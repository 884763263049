import React from 'react';
import { StakingView } from '../modules/Staking/idex';
import css from './styles.module.scss';
import { RewardsView } from '../modules/Rewards';
import { HowItWorksView } from '../modules/HowItWorks';
export const StakingPage = () => {
  return (
    <div className={css.stakingPage}>
      <StakingView />
      <RewardsView />
      <HowItWorksView />
    </div>
  );
};
