import React from "react";

export const SmartIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 1.16675V3.50008" stroke="white" strokeLinecap="round" />
      <path
        d="M4.95833 2.16187C3.07303 2.95841 1.75 4.82474 1.75 7.00009C1.75 9.89958 4.1005 12.2501 7 12.2501C9.89949 12.2501 12.25 9.89958 12.25 7.00009C12.25 4.82474 10.927 2.95841 9.04167 2.16187"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};
