import axios from "axios";

const responseWrapper = async (f: Promise<any>, needErrorMessage?: boolean) => {
  try {
    const response = await f;
    return response.status === 200 ? response.data : { responseError: "Error" };
  } catch (e: any) {
    const errorMessage = e?.response?.data?.message ?? "Error";
    if (needErrorMessage) {
      return { responseError: errorMessage };
    }
  }
};

const credentialConfig = {
  withCredentials: false,
};

type UserInformationAndProofType = UserInformationType & UserProofsType;

export interface UserInformationType {
  floorTickets: string;
  realTickets: string;
}

export interface UserProofsType {
  amount: number;
  index: number;
  proofs: string[];
}

const ENDPOINT = "https://api.staking.tenet.org"; // MAINNET
// const ENDPOINT = "https://api.step.app/dev"; // TESTNET
export const STAKING_API_ENDPOINT = "https://api-staking.step.app/v1/"; // MAINNET
// export const STAKING_API_ENDPOINT = "https://dev.api-staking.step.app/v1/"; // TESTNET

export const getUserInformationAndProofs: (
  epoch: number,
  address: string
) => Promise<UserInformationAndProofType> = async (epoch, address) =>
  responseWrapper(
    axios.get(
      `${ENDPOINT}/v1/epoch/${epoch}/wallet/${address}`,
      credentialConfig
    )
  );

export const getIfShowRewards = async (): Promise<boolean | undefined> => {
  return await responseWrapper(axios.get(`${ENDPOINT}/v1/state/get_state`));
};
