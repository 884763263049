import { ContractInterface, ethers } from "ethers";
import { useMemo } from "react";
import { useWeb3Connect } from "../contexts/Web3ProviderContext";
import { useWeb3ModalSigner } from "@web3modal/ethers5/react";

export const useContract = (
  address: string | undefined,
  ABI: ContractInterface,
  withSignerIfPossible = true
) => {
  const { web3Provider, isEthChainConnected } = useWeb3Connect();
  const { signer } = useWeb3ModalSigner();
  return useMemo(
    () =>
      signer && address ? new ethers.Contract(address, ABI, withSignerIfPossible ? signer : web3Provider) : null,
    [web3Provider, isEthChainConnected]
  );

  /*const { library } = useWeb3React();

  return useMemo(() => {
    if (!address || !ABI) return null;
    try {
      if (!withSignerIfPossible) {
        return new ethers.Contract(address, ABI, new ethers.providers.JsonRpcProvider(mainConfig.rpc));
      }
      if (!library) return null;
      return new ethers.Contract(address, ABI, library?.getSigner());
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible]);*/
};
