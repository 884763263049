import React from "react";
import css from "./styles.module.scss";
import { Outlet } from "react-router-dom";
import { HeaderView } from "../modules/Header";
import { FooterView } from "../modules/Footer";
import bgVideo from "../assets/reversed_staking_compressed.mp4";
import { BannerView } from "../modules/Banner";
import {useWeb3Connect} from "../contexts/Web3ProviderContext";

export const AppLayout = () => {
  const {networkId} = useWeb3Connect();

  return (
    <div className={css.inner}>
        {networkId == 1 && <BannerView />}
      <video autoPlay loop muted className={css.bgVideo}>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <HeaderView />
      <main className={css.mainContainer}>
        <Outlet />
      </main>
      <FooterView />
    </div>
  );
};
