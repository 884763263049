import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTES } from "./types/enums/routeNames";
import { StakingPage } from "./pages/StakingPage";
import { AppLayout } from "./Layout";
import {
  TENET_STAKING_ADDRESS,
  TENET_STAKING_ADDRESS_ETH,
} from "./contracts/tenetStaking";
import StakingAbi from "./contracts/tenetStaking/TenetStakingNative.json";
import useAppStore from "./stores/appStore";
import useStakingStore from "./modules/Staking/store";
import { shallow } from "zustand/shallow";
import { useWeb3Connect } from "./contexts/Web3ProviderContext";
import StakingAbiErc20 from "./contracts/tenetStaking/TenetStakingNativeErc20.json";
import { useContract } from "./hooks/useContract";
import { useGetUserBalance } from "./hooks/useGetUserBalance";
import { web3ModalConfig } from "./hooks/useWeb3Provider";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata: web3ModalConfig.metadata }),
  chains: web3ModalConfig.chains,
  projectId: web3ModalConfig.projectId,
});

function App() {
  const { account, isEthChainConnected } = useWeb3Connect();
  const { stakerStakeCount } = useStakingStore(
    (store) => ({ ...store }),
    shallow
  );
  const getUserBalance = useGetUserBalance();

  const stakingContract = useContract(
    isEthChainConnected ? TENET_STAKING_ADDRESS_ETH : TENET_STAKING_ADDRESS,
    isEthChainConnected ? StakingAbiErc20 : StakingAbi,
    false
  );
  useEffect(() => {
    if (isEthChainConnected && stakingContract) {
      stakingContract.decimals().then((stakingDecimals: number) => {
        useAppStore.setState({ stakingDecimals });
      });
    }
  }, [stakingContract]);
  useEffect(() => {
    getUserBalance();
  }, [account]);

  return (
    <Routes>
      <Route path={ROUTES.STAKING} element={<AppLayout />}>
        <Route index element={<StakingPage />} />
      </Route>
    </Routes>
  );
}

export default App;
