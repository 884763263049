import React from 'react';
import css from './styles.module.scss';
import cx from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

interface PropsTypes extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  variant: 'primary' | 'secondary' | 'primaryBorder' | 'danger';
  loading?: boolean;
  className?: string;
  link?: string;
  Icon?: JSX.Element;
}

const Button = ({ text, loading = false, variant, className = '', link = '', Icon, ...props }: PropsTypes) => {
  return (
    <button
      className={cx(
        css.btnGeneral,
        {
          [css.btnPrimary]: variant === 'primary' || variant === 'danger',
          [css.btnPrimaryWithBorder]: variant === 'primaryBorder',
          [css.btnSecondary]: variant === 'secondary',
          [css.btnDanger]: variant === 'danger',
        },
        className
      )}
      {...props}
    >
      {loading ? (
        <CircularProgress
          size={20}
          classes={{
            colorPrimary: cx({
              [css.loaderSecondaryColor]: variant === 'secondary' || variant === 'danger',
              [css.loaderPrimaryColor]: variant === 'primary',
            }),
          }}
        />
      ) : link ? (
        <a href={link} target="_blank">
          {text}
        </a>
      ) : (
        <>
          {Icon && Icon}
          <span>{text}</span>
        </>
      )}
    </button>
  );
};

export default Button;
