import React, { useEffect, useRef } from "react";
import css from "./styles.module.scss";
import { REWARDS_LIST } from "../../constants/mockups";
import useWindowSize from "../../hooks/useWindowSize";
import { MAX_MOBILE_WIDTH } from "../../constants";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
interface PropsTypes {}

export const RewardsView = ({}: PropsTypes) => {
  const { width } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.scrollLeft =
        container.scrollWidth / 2 - container.clientWidth / 5;
    }
  }, [containerRef]);
  return (
    <section className={css.rewardsWrapper}>
      <h2 className={css.rewardsTitle}>Get rewards for your stakes</h2>
      <div className={css.rewardsScrollableWrapper} ref={containerRef}>
        {width > MAX_MOBILE_WIDTH ? (
          <Swiper
            className={css.rewardList}
            slidesPerView={'auto'}
          >
            {REWARDS_LIST.map((item, i) => (
              <SwiperSlide key={i} className={css.rewardItem}>
                <div className={css.rewardImgWrapper}>
                  <img
                    className={css.rewardImg}
                    src={item.img}
                    alt="rewards image"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            <ul className={cn(css.rewardList, css.rewardsListMobile)}>
              {REWARDS_LIST.slice(0, REWARDS_LIST.length / 2).map((item, i) => (
                <li key={i} className={css.rewardItem}>
                  <div className={css.rewardImgWrapper}>
                    <img
                      className={css.rewardImg}
                      src={item.img}
                      alt="rewards image"
                    />
                  </div>
                </li>
              ))}
            </ul>
            <ul className={cn(css.rewardList, css.rewardsListMobile)}>
              {REWARDS_LIST.slice(REWARDS_LIST.length / 2).map((item, i) => (
                <li key={i} className={css.rewardItem}>
                  <div className={css.rewardImgWrapper}>
                    <img
                      className={css.rewardImg}
                      src={item.img}
                      alt="rewards image"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </section>
  );
};
