import { ethers } from 'ethers';
import ABI from '../contracts/multicalls/MulticallABI.json';
import { NETWORK, networkInfo, networkToId } from '../utils/network';
import { MULTICALL_ADDRESS } from '../contracts/multicalls';
import { MULTICALL_ADDRESS_ETH } from '../contracts/multicalls';
import { useWeb3Connect } from '../contexts/Web3ProviderContext';

const useMulticall = () => {
  const { isEthChainConnected } = useWeb3Connect();
  const provider = new ethers.providers.JsonRpcProvider(
    networkInfo[isEthChainConnected ? NETWORK.ethereum : NETWORK.tenet].rpcUrls[0],
    +networkToId[isEthChainConnected ? NETWORK.ethereum : NETWORK.tenet]
  );
  return new ethers.Contract(isEthChainConnected ? MULTICALL_ADDRESS_ETH : MULTICALL_ADDRESS, ABI, provider);
};

export default useMulticall;
