import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import css from "../styles.module.scss";
import cn from "classnames";
import Button from "../../../UI-core/Buttton";
import { WithdrawIcon } from "../../../assets/svgComponents/WithdrawIcon";
import { SELECT_OPTIONS } from "../../../constants/mockups";
import UiDrawer from "../../../UI-core/Drawer/inex";
import { WithdrawalView } from "../../Withdrawal";
import { SuccessStakeUnstake } from "../../../UI-core/SuccessStakeUnstake";
import { useStakingIteractor } from "../iteractor";
import useAppStore from "../../../stores/appStore";
import useStakingStore from "../store";
import { useWeb3Connect } from "../../../contexts/Web3ProviderContext";
import { useToaster } from "../../Toaster/ToasterContext";
import { validationSum } from "../../../utils/validationSum";
import { timer } from "../../../utils/timer";
import { EVA_STAKING_DATE } from "../../../constants";
import { shallow } from "zustand/shallow";
import { calculateIncomeBonus, toNormalFixed } from "../../../utils";
export const StakeAmountBlock = () => {
  const [amountToStake, setAmountToStake] = useState<string>();
  const [isStakedSuccess, setIsStakedSuccess] = useState<boolean>(false);
  const { account, onConnect, currencySymbol, networkId } = useWeb3Connect();
  const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false);
  const { isStaking, stakedBalance, stakerStakeCount, income } =
    useStakingStore((store) => ({ ...store }), shallow);
  const { stakeTokens, calcShares } = useStakingIteractor();
  const { userBalance, isConnectingWallet } = useAppStore();
  const { setAlert } = useToaster();

  const [tickTimer, setTickTimer] = useState("");
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      const timerDate = timer(EVA_STAKING_DATE);
      if (!Array.isArray(timerDate)) {
        setTickTimer(timerDate);
      }
    }, 1000);
  }, []);

  const handleStakeTokens = useCallback(async () => {
    const isStaked = await stakeTokens(Number(amountToStake) || 0);
    isStaked && setIsStakedSuccess(true);
  }, [stakeTokens, amountToStake]);

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let validPrays = validationSum(e.target.value);
    setAmountToStake(validPrays);
  };

  const incomeBonus = useMemo(
    () =>
      amountToStake
        ? toNormalFixed(calculateIncomeBonus(income, +amountToStake), 2)
        : 0,
    [amountToStake, income]
  );

  useEffect(() => {
    amountToStake && calcShares(amountToStake);
  }, [amountToStake]);

  return (
    <div className={css.stakeAmountWrapper}>
      <div className={css.howMuchWrapper}>
        <div className={css.howMuchTop}>
          <h3 className={css.howMuchTitle}>How much do you want to stake?</h3>
        </div>
        <div className={css.amountToStakeInputWrapper}>
          {amountToStake && (
            <div className={css.incomeWrapper}>
              <span className={css.incomeEntries}>
                +{income} Entries per day
              </span>{" "}
              <br />
              <span
                className={cn(
                  css.incomeBonus,
                  incomeBonus <= 0 && css.incomeNoBonus
                )}
              >
                ({incomeBonus > 0 ? "+" : ""}
                {income > 0 ? incomeBonus : 0} Amount Bonus Applied)
              </span>
            </div>
          )}
          <input
            placeholder={"Enter amount"}
            value={amountToStake}
            onChange={handleChangeAmount}
            className={css.amountToStakeInput}
          />
        </div>
        <div className={css.howMuchBottom}>
          <div className={css.maxWrapper}>
            <Button
              text={"MAX"}
              variant={"primaryBorder"}
              className={css.maxBtn}
              onClick={() => setAmountToStake(userBalance)}
            />
            <p className={css.balanceText}>{userBalance} TENET</p>
          </div>
          <ul className={css.stakeOptionsList}>
            {SELECT_OPTIONS.map((item, i) => (
              <li
                key={i}
                onClick={() => {
                  if (item <= +userBalance) {
                    setAmountToStake(`${item}`);
                  } else {
                    setAlert({
                      shown: true,
                      message: "Insufficient balance",
                      severity: "warning",
                    });
                  }
                }}
                className={css.stakeOptionItem}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={css.stakeAndWithdrawWrapper}>
        <div className={css.stakedAmountWrapper}>
          <p className={css.stakedAmount}>Staked: {stakedBalance} TENET</p>
          <Button
            text={"Withdraw"}
            variant={"primaryBorder"}
            Icon={<WithdrawIcon className={css.withdrawIcon} />}
            className={css.withdrawDtn}
            onClick={() => setWithdrawOpen(true)}
            disabled={stakerStakeCount <= 0}
          />
        </div>
        {account ? (
          <Button
            text={`Stake`}
            variant={"primary"}
            className={css.stakeBtn}
            disabled={
              !amountToStake ||
              +amountToStake <= 0 ||
              +userBalance < +amountToStake ||
              networkId === 1
            }
            onClick={handleStakeTokens}
            loading={isStaking}
          />
        ) : (
          <Button
            text={"Connect wallet"}
            variant={"primary"}
            loading={isConnectingWallet}
            onClick={() => onConnect()}
            className={cn(css.stakeBtn, css.connectWalletBtn)}
          />
        )}
      </div>
      <UiDrawer onClose={() => setWithdrawOpen(false)} open={withdrawOpen}>
        <WithdrawalView isOpen={withdrawOpen} />
      </UiDrawer>
      <UiDrawer
        onClose={() => setIsStakedSuccess(false)}
        open={isStakedSuccess}
      >
        <SuccessStakeUnstake
          title={`You have successfully staked ${amountToStake} TENET`}
          subtitle={""}
          onClose={() => {
            setIsStakedSuccess(false);
            setAmountToStake("");
          }}
        />
      </UiDrawer>
    </div>
  );
};
