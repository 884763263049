import { ethers, BigNumber } from "ethers";

const SECOND = 1;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const beautifyTokenBalance = (
  balance: string,
  decimals: number,
  fraction = 5
) => {
  const exp = 10 ** fraction;

  return (+balance.slice(0, -decimals + fraction) / exp).toLocaleString(
    undefined,
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: fraction,
    }
  );
};

export const fromHRToBN = (n: number, decimals: number) => {
  n = Math.floor(n);
  const MAX_SMALL_DECIMAL = 6;

  if (decimals <= MAX_SMALL_DECIMAL) {
    return ethers.utils.parseUnits(n.toString(), decimals);
  }

  const multiplierSmall = ethers.utils.parseUnits("1", MAX_SMALL_DECIMAL);
  const multiplierMain = ethers.utils.parseUnits(
    "1",
    decimals - MAX_SMALL_DECIMAL
  );

  return BigNumber.from(n.toString()).mul(multiplierSmall).mul(multiplierMain);
};

export const toHRNumber = (bn: ethers.BigNumber, decimal: number) => {
  if (!decimal) {
    return 0;
  }
  return ethers.utils.formatUnits(bn, decimal);
};
export const toHRNumberFloat = (bn: ethers.BigNumber, decimal = 0) => {
  return toHRNumber(bn, decimal);
};

export const ceilNumber = (n: number) => Math.ceil(n * 100) / 100;

export const floorNumber = (n: number) => Math.floor(n * 100) / 100;
