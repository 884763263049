import React, { useEffect, useRef, useState } from "react";
import css from "../styles.module.scss";
import { getDdMmYy } from "../../../utils";
import Button from "../../../UI-core/Buttton";
import { timer } from "../../../utils/timer";
import cn from "classnames";
import UiDrawer from "../../../UI-core/Drawer/inex";
import { IsSureToWithdraw } from "./IsSureToWithdraw";
import { useWithdrawIteractor } from "../ineractor";
import useStakingStore from "../../Staking/store";
import useAppStore from "../../../stores/appStore";
import { toHRNumberFloat } from "../../../utils/bigNumber";
import { useWeb3Connect } from "../../../contexts/Web3ProviderContext";
import { ethers } from "ethers";

interface PropsTypes {
  isUnstaked: boolean;
  amount: string;
  perDayAmount?: string;
  stakingDate: Date;
  untilPenaltyDate: Date;
  penaltyDays: number;
  id: number;
  reward: ethers.BigNumber;
  penaltyBP: string | undefined;
}

export const StakingItem = ({
  isUnstaked,
  amount,
  perDayAmount,
  stakingDate,
  untilPenaltyDate,
  penaltyDays,
  id,
  reward,
  penaltyBP,
}: PropsTypes) => {
  const [isReadyToUnstake, setIsReadyToUnstake] = useState<boolean>(false);
  const [tickTimer, setTickTimer] = useState<string>();
  const { currencySymbol } = useWeb3Connect();
  const [isWithoutPenalty, setIsWithoutPenalty] = useState<boolean>(
    Date.now() <= +untilPenaltyDate
  );
  const [isGoingToWithdraw, setIsGoingToWithdraw] = useState<boolean>(false);
  const { unstake, cancelUnstake, withdraw } = useWithdrawIteractor();
  const { unstaking, newEpoch, maxBPS } = useStakingStore();
  const { stakingDecimals } = useAppStore();
  const dt = reward
    ? toHRNumberFloat(reward, +stakingDecimals).toLocaleString("en-us")
    : 0;
  const penalty = penaltyBP && maxBPS ? (+penaltyBP / maxBPS) * 100 : 0;

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isUnstaked && untilPenaltyDate && Date.now() <= +untilPenaltyDate) {
      intervalIdRef.current = setInterval(() => {
        const timerDate = timer(new Date(untilPenaltyDate), false);
        if (!Array.isArray(timerDate)) {
          setTickTimer(timerDate);
        }
        setIsWithoutPenalty(Date.now() <= +untilPenaltyDate);
      }, 1000);
    }
  }, []);

  return (
    <div
      key={id}
      className={cn(
        css.stakingItemWrapper,
        isUnstaked && isWithoutPenalty && css.stakingUnstakedItemWrapper
      )}
    >
      <div className={css.stakingDateWrapper}>
        <p className={css.stakingDate}>{getDdMmYy(stakingDate)}</p>
        {isUnstaked && <span className={css.unstakedLabel}>Unstaked</span>}
      </div>
      <div className={css.stakingItemTop}>
        <div className={css.stakingItemTopLeft}>
          <p className={css.stakeAmount}>{amount} TENET</p>
          {perDayAmount && !isUnstaked && (
            <p className={css.stakeAmountPerDay}>{perDayAmount} Per day</p>
          )}
        </div>
        {!isUnstaked && (
          <Button
            text={"Unstake"}
            onClick={() => setIsReadyToUnstake(true)}
            variant={"secondary"}
            disabled={isReadyToUnstake}
            className={css.unstakeBtn}
          />
        )}
      </div>
      {isReadyToUnstake && !isUnstaked && (
        <>
          <p className={css.warningText}>
            After withdrawal you will lose accumulated Entries on this deposit
            in the amount of {dt} Entries #{+(newEpoch?.epochIndex ?? 0) + 1}.
            Also {penalty}% early withdrawal fee will be deducted.
          </p>
          <div className={css.unstakeBtnsWrapper}>
            <Button
              text={"Cancel"}
              variant={"secondary"}
              onClick={() => setIsReadyToUnstake(false)}
              className={css.cancelBtn}
            />
            <Button
              text={"Unstake"}
              variant={"primary"}
              className={css.redyToUnstakeBtn}
              onClick={async () => await unstake(id)}
              loading={unstaking.loading && unstaking.id === id}
            />
          </div>
        </>
      )}
      {isUnstaked && untilPenaltyDate && (
        <div className={css.unstakedWraper}>
          <p className={css.untilPenaltyWrapper}>
            <span className={css.untilPenaltyText}>Until 0% penalty:</span>
            <br />
            <span className={css.untilPenaltyTimer}>{tickTimer}</span>
          </p>
          <div className={css.unstakeBtnsWrapper}>
            <Button
              text={"Cancel unstake"}
              variant={"primary"}
              onClick={async () => await cancelUnstake(id)}
              className={css.cancelBtn}
              loading={unstaking.loading && unstaking.id === id}
            />
            <Button
              text={"Withdraw"}
              variant={"danger"}
              className={css.redyToUnstakeBtn}
              onClick={async () =>
                isWithoutPenalty
                  ? setIsGoingToWithdraw(true)
                  : await withdraw(id, false)
              }
              loading={
                !isWithoutPenalty && unstaking.loading && unstaking.id === id
              }
            />
          </div>
        </div>
      )}
      <UiDrawer
        open={isGoingToWithdraw}
        onClose={() => setIsGoingToWithdraw(false)}
        isDanger
      >
        <IsSureToWithdraw
          penalty={penalty}
          onClose={() => setIsGoingToWithdraw(false)}
          onProceed={withdraw}
          id={id}
          loading={unstaking.loading && unstaking.id === id}
        />
      </UiDrawer>
    </div>
  );
};
