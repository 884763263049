import { create } from 'zustand';
export interface IAppStore {
  userBalance: string;
  stakingDecimals: number;
  isConnectingWallet: boolean;
}

const useAppStore = create<IAppStore>()((set, get) => {
  return {
    userBalance: '0',
    stakingDecimals: 18,
    isConnectingWallet: false,
  };
});

export default useAppStore;
