import { TwitterIcon } from "../assets/svgComponents/TwitterIcon";
import { DiscordIcon } from "../assets/svgComponents/DiscordIcon";
import { TgIcon } from "../assets/svgComponents/TgIcon";
import { GithubIcon } from "../assets/svgComponents/GithubIcon";
import img1 from "../assets/rewards/1.png";
import img2 from "../assets/rewards/2.png";
import img3 from "../assets/rewards/3.png";
import img4 from "../assets/rewards/4.png";
import img5 from "../assets/rewards/5.png";
import img6 from "../assets/rewards/6.png";
import img7 from "../assets/rewards/7.png";
import img8 from "../assets/rewards/8.png";
import img9 from "../assets/rewards/9.png";
import img10 from "../assets/rewards/10.png";
import img11 from "../assets/rewards/11.png";
import img12 from "../assets/rewards/12.png";
import img13 from "../assets/rewards/13.png";
import img14 from "../assets/rewards/14.png";
import img15 from "../assets/rewards/15.png";
import img16 from "../assets/rewards/16.png";
import img17 from "../assets/rewards/17.png";
import img18 from "../assets/rewards/18.png";
import img19 from "../assets/rewards/19.png";
import img20 from "../assets/rewards/20.png";
import img21 from "../assets/rewards/21.png";
import img22 from "../assets/rewards/22.png";
import img23 from "../assets/rewards/23.png";
import img24 from "../assets/rewards/24.png";
import img25 from "../assets/rewards/25.png";
import img26 from "../assets/rewards/26.png";
import img27 from "../assets/rewards/27.png";
import img28 from "../assets/rewards/28.png";
import img29 from "../assets/rewards/29.png";
import img30 from "../assets/rewards/30.png";
import { StatusStates } from "../types/enums";
import { ClockIcon } from "../assets/svgComponents/ClockIcon";
import { RewardIcon } from "../assets/svgComponents/RewardIcon";
import { BlueSmile } from "../assets/svgComponents/BlueSmile";
import BackPack1 from "../assets/howItWorks/img1.png";
import BackPack2 from "../assets/howItWorks/img2.png";
import BackPack3 from "../assets/howItWorks/img3.png";

import Bitfinex from "../assets/getTenetIcon/Bitfinex.png";
import Bitmart from "../assets/getTenetIcon/Bitmart.png";
import Bybit from "../assets/getTenetIcon/Bybit.png";
import Gate from "../assets/getTenetIcon/Gate.png";
import Huobi from "../assets/getTenetIcon/Huobi.png";
import Kucoin from "../assets/getTenetIcon/Kucoin.png";
import Mexc from "../assets/getTenetIcon/Mexc.png";

import LootBox1 from "../assets/raffles/scanners.gif";

export const TOASTER_ALERTS = {
  wallet: {
    connected: "wallet was successfully connected",
    disconnected: "wallet was disconnected",
    unableToConnect:
      "Please, download the browser extension or set it as the default wallet in the settings",
    disableTrustWallet:
      "Disable using Trust wallet as a default wallet in the settings to connect via metamask",
  },
};

export const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

export const HEADER_NAV = [
  { text: "AI on TENET", link: "#" },
  { text: "EVA", link: "#" },
  { text: "EVM on Cosmos", link: "#" },
  { text: "Security", link: "#" },
  { text: "IDLe", link: "#" },
  { text: "Earn", link: "#" },
];

export const HEADER_SOCIALS = [
  { link: "https://twitter.com/tenet_org", logo: TwitterIcon },
  { link: "https://tenet.org/#", logo: DiscordIcon },
  { link: "https://t.me/TENETPublicChat", logo: TgIcon },
  { link: "https://github.com/tenet-org", logo: GithubIcon },
];

export const SELECT_OPTIONS = [250, 500, 750, 1000];

export const REWARDS_LIST = [
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img4 },
  { img: img5 },
  { img: img6 },
  { img: img7 },
  { img: img8 },
  { img: img9 },
  { img: img10 },
  { img: img11 },
  { img: img12 },
  { img: img13 },
  { img: img14 },
  { img: img15 },
  { img: img16 },
  { img: img17 },
  { img: img18 },
  { img: img19 },
  { img: img20 },
  { img: img21 },
  { img: img22 },
  { img: img23 },
  { img: img24 },
  { img: img25 },
  { img: img26 },
  { img: img27 },
  { img: img28 },
  { img: img29 },
  { img: img30 },
];
// @ts-ignore
export const RAFFLE_RESULT: Record<
  StatusStates,
  { icon: JSX.Element | null; text: string }
> = {
  [StatusStates.WAITING]: { icon: <ClockIcon />, text: "Waiting..." },
  [StatusStates.WIN]: { icon: <RewardIcon />, text: "Congrats! You Won!" },
  [StatusStates.LOSE]: { icon: <BlueSmile />, text: "Sorry. You haven’t won" },
  [StatusStates.LOADING]: { icon: null, text: "" },
  [StatusStates.FAILED]: { icon: null, text: "" },
};

export const HOW_IT_WORKS_ITEMS = [
  {
    title: "Earn inside Eva Wallet",
    text: [
      "Stake your $TENET tokens and get a limited collection of NFTs that will allow you to earn token rewards inside Eva Wallet.",
    ],
    img: BackPack1,
  },
  {
    title: "Scanner NFTs",
    text: [
      "Raffles are held every 2 weeks. You can see the countdown till the next raffle on staking page. Winning NFTs in the raffle is based on luck, but staking more tokens increases the number of entries and improves the odds of winning.",
      "A single wallet cannot receive more than 5 NFTs per raffle.",
    ],
    img: BackPack2,
  },
  {
    title: "No locks",
    text: [
      "You can leave the staking whenever you like, but in this case your entries will not be applied. Once you click on Unstake, the cooldown period of 14 days begin. You can either wait for the timer to complete or pay the penalty of 16% for early withdrawal.",
      "During the cooldown period you can still cancel Unstake and get back the accumulated entries.",
    ],
    img: BackPack3,
  },
];
export const arrCrypto = [
  {
    icon: Bybit,
    text: "Bybit",
    isWarningVisible: false,
    link: "https://partner.bybit.com/b/tenetexclusive",
  },
  {
    icon: Huobi,
    text: "Huobi",
    isWarningVisible: false,
    link: "https://www.huobi.com/en-us/exchange/tenet_usdt",
  },
  {
    icon: Kucoin,
    text: "KuCoin",
    isWarningVisible: false,
    link: "https://www.kucoin.com/trade/TENET-USDT",
  },
  {
    icon: Bitfinex,
    text: "Bitfinex",
    isWarningVisible: false,
    link: "https://trading.bitfinex.com/t/TENET:UST",
  },
  {
    icon: Bitmart,
    text: "Bitmart",
    isWarningVisible: false,
    link: "https://www.bitmart.com/trade/en-US?layout=basic&theme=dark&symbol=TENET_USDT",
  },
  {
    icon: Gate,
    text: "Gate",
    isWarningVisible: false,
    link: "https://www.gate.io/trade/TENET_USDT",
  },
  {
    icon: Mexc,
    text: "MEXC",
    isWarningVisible: false,
    link: "https://www.mexc.com/exchange/TENET_USDT",
  },
];

export const LOOT_BOX = {
  1: LootBox1,
  2: LootBox1,
};
