import React from "react";

interface PropsTypes extends React.SVGProps<SVGElement> {
  isCopied: boolean;
}
export const CopyIcon = (props: PropsTypes) => {
  if (props.isCopied) {
    return (
      <svg
        width="16"
        height="16"
        className={props.className}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27 9L13 23L6 16"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={props.className}
        onClick={props.onClick}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_38)">
          <path
            d="M11.1562 13.125H5.03125C4.50911 13.125 4.00835 12.9176 3.63913 12.5484C3.26992 12.1792 3.0625 11.6784 3.0625 11.1562V5.03125C3.0625 4.50911 3.26992 4.00835 3.63913 3.63913C4.00835 3.26992 4.50911 3.0625 5.03125 3.0625H11.1562C11.6784 3.0625 12.1792 3.26992 12.5484 3.63913C12.9176 4.00835 13.125 4.50911 13.125 5.03125V11.1562C13.125 11.6784 12.9176 12.1792 12.5484 12.5484C12.1792 12.9176 11.6784 13.125 11.1562 13.125Z"
            fill="#7E87A1"
          />
          <path
            opacity="0.5"
            d="M4.375 2.1875H10.8248C10.6887 1.80393 10.4373 1.47188 10.1049 1.23689C9.77263 1.0019 9.37575 0.875491 8.96875 0.875H2.84375C2.32161 0.875 1.82085 1.08242 1.45163 1.45163C1.08242 1.82085 0.875 2.32161 0.875 2.84375V8.96875C0.875491 9.37575 1.0019 9.77263 1.23689 10.1049C1.47188 10.4373 1.80393 10.6887 2.1875 10.8248V4.375C2.1875 3.79484 2.41797 3.23844 2.8282 2.8282C3.23844 2.41797 3.79484 2.1875 4.375 2.1875Z"
            fill="#7E87A1"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_38">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
