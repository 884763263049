import React, {useEffect, useRef, useState} from "react";
import css from "./styles.module.scss";
import {EVA_STAKING_DATE} from "../../constants";
import {timer} from "../../utils/timer";
import cn from "classnames";

export const BannerView = () => {
    const [tickTimer, setTickTimer] = useState("");
    const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        intervalIdRef.current = setInterval(() => {
            const timerDate = timer(EVA_STAKING_DATE);
            if (!Array.isArray(timerDate)) {
                setTickTimer(timerDate);
            }
        }, 1000);
    }, []);
    return (
        <section className={css.bannerWrapper}>
            <div className={css.bannerBody}>
                <p className={css.bannerBodyText}>
                    <p className={cn(css.infoBanner)}>
                        Tenet (TENET) has migrated from their
                        <a className={cn(css.link)}
                           href="https://etherscan.io/token/0x9663677b81c2d427e81c01ef7315ea96546f5bb1"
                           target={'_blank'}>
                            <span> </span> ERC20 contract <span> </span>
                        </a>
                        to a
                        <a target={'_blank'} className={cn(css.link)}
                           href="https://etherscan.io/token/0x788D86E00ab31Db859C3d6b80d5a9375801d7f2A">
                            <span> </span> new OFT
                            contract
                        </a>.
                        For more information,
                        please view this
                        <a target={'_blank'} className={cn(css.link)}
                           href="https://medium.com/@tenet_org/introducing-tenet-bridge-dac7944e5717">
                            <span> </span> Medium post <span> </span>
                        </a>
                        and
                        <a target={'_blank'} className={cn(css.link)}
                           href="https://twitter.com/tenet_org/status/1689652351068729345">
                            <span> </span> announcement on Twitter
                        </a>.
                        <br/>
                        Going forwards, staking in Eva will only be supported on Tenet mainnet.
                        Current stakers on Ethereum will continue to participate in the following Raffles.
                    </p>
                </p>
            </div>
        </section>
    )
};
