import React from 'react';
import css from './styles.module.scss';
import cn from 'classnames';
import { Drawer, DrawerProps } from '@mui/material';
import { CloseIcon } from '../../assets/svgComponents/CloseIcon';

interface PropsTypes extends DrawerProps {
  children: React.ReactNode;
  isDanger?: boolean;
}

const UiDrawer = ({ children, isDanger, ...props }: PropsTypes) => {
  return (
    <Drawer
      anchor={'right'}
      {...props}
      classes={{ root: css.drawerRoot, paper: cn(css.drawerPaper, isDanger && css.drawerPaperDanger) }}
    >
      <CloseIcon
        className={css.closeIcon}
        onClick={() => {
          // @ts-ignore
          props.onClose();
        }}
      />
      {children}
    </Drawer>
  );
};

export default UiDrawer;
