import React from 'react';

export const ArrowIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9.81543"
        y="4.35608"
        width="8.93053"
        height="0.917366"
        transform="rotate(45 9.81543 4.35608)"
        fill="white"
      />
      <rect
        x="9.12207"
        y="16.3761"
        width="8.38001"
        height="0.921153"
        transform="rotate(-45 9.12207 16.3761)"
        fill="white"
      />
    </svg>
  );
};
