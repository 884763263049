import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/resetAndGlobal.scss";
import { Buffer } from "buffer";
import { BrowserRouter } from "react-router-dom";
import { Web3ProviderContextProvider } from "./contexts/Web3ProviderContext";
import { ToasterContextProvider } from "./modules/Toaster/ToasterContext";

window.Buffer = window.Buffer || Buffer;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ToasterContextProvider>
      <BrowserRouter>
        <Web3ProviderContextProvider>
          <App />
        </Web3ProviderContextProvider>
      </BrowserRouter>
    </ToasterContextProvider>
  </React.StrictMode>
);
