import React from 'react';
import css from './styles.module.scss';
import { InfoBlock } from './components/InfoBlock';
import { StakeAmountBlock } from './components/StakeAmountBlock';

interface PropsTypes {}

export const StakingView = ({}: PropsTypes) => {
  return (
    <>
      <h1 className={css.mainTitle}>Tenet EVA Staking</h1>
      <section className={css.stakingContainer}>
        <h2 className={css.stakingTitle}>Staking</h2>
        <InfoBlock />
        <StakeAmountBlock />
      </section>
    </>
  );
};
