import React from "react";
import css from "../../../UI-core/SuccessStakeUnstake/styles.module.scss";
import Button from "../../../UI-core/Buttton";
import { SadSmile } from "../../../assets/svgComponents/SadSmile";

interface PropsTypes {
  onClose: () => void;
  onProceed: (id: number, force?: boolean) => Promise<boolean | undefined>;
  loading: boolean;
  id: number;
  penalty?: number;
}

export const IsSureToWithdraw = ({
  onClose,
  onProceed,
  loading,
  id,
  penalty,
}: PropsTypes) => {
  return (
    <div className={css.successWrapper}>
      <div />
      <SadSmile className={css.successSmile} />
      <div className={css.successContentWrapper}>
        <h3 className={css.successContentTitle}>
          Are you sure? Your entries will burnt.
        </h3>
        <p className={css.successContentSubtitle}>
          {penalty || 16}% early withdrawal fee will also be deducted.
          Otherwise, if you cancel Unstake, you will get back the entries
          accumulated during cooldown period.
        </p>
        <div className={css.btnsWrapper}>
          <Button
            text={"Cancel"}
            variant={"primary"}
            className={css.cancelBtn}
            onClick={onClose}
          />
          <Button
            text={"Proceed"}
            variant={"danger"}
            className={css.proceedBtn}
            onClick={async () => {
              const isWithdrawn = await onProceed(id, true);
              isWithdrawn && onClose();
            }}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
