import React, { useState } from "react";
import css from "./styles.module.scss";
import { Logo } from "../../assets/svgComponents/Logo";
import { Link } from "react-router-dom";
import { ROUTES } from "../../types/enums/routeNames";
import Button from "../../UI-core/Buttton";
import useAppStore from "../../stores/appStore";
import useCopyClipboard from "../../hooks/useCopyToClipboard";
import { CopyIcon } from "../../assets/svgComponents/CopyIcon";
import cn from "classnames";
import { shortenAddress } from "../../utils";
import { WalletIcon } from "../../assets/svgComponents/WalletIcon";
import { ExpandArrow } from "../../assets/svgComponents/ExpandArrow";
import { useWeb3Connect } from "../../contexts/Web3ProviderContext";
import UiDrawer from "../../UI-core/Drawer/inex";
import { GetTenetView } from "../GetTenet";
import {
  changeNetworkAtMetamask,
  idToNetwork,
  NETWORK,
  networkInfo,
  shortenNetworkNames,
} from "../../utils/network";
import EthIcon from "../../assets/eth-icon.png";
import TenetIcon from "../../assets/tenet-icon.png";
import { Popover } from "@mui/material";
import { CheckIcon } from "../../assets/svgComponents/CheckIcon";
import { useToaster } from "../Toaster/ToasterContext";
import { SmartIcon } from "../../assets/svgComponents/SmartIcon";

export const HeaderView = () => {
  const {
    account,
    onConnect,
    isCorrectChainId,
    onDisconnect,
    networkId,
    currencySymbol,
    web3Provider,
  } = useWeb3Connect();
  const [isCopied, setCopied] = useCopyClipboard(2000);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { userBalance, isConnectingWallet } = useAppStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { setAlert } = useToaster();

  const openChainsList = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [isOpenRafflesDetails, setIsOpenRafflesDetails] =
    useState<boolean>(false);

  return (
    <header className={css.header}>
      <div className={css.chainslogoWrapper}>
        <div className={css.logoWrapper}>
          <Link to={ROUTES.STAKING}>
            <Logo className={css.mainLogo} />
          </Link>
          <span className={css.logoText}>Eva</span>
        </div>
        {networkId && isCorrectChainId && (
          <div className={css.chainsWrapper}>
            <div
              className={cn(css.activeChain, !!anchorEl && css.activeChainOpen)}
              onClick={openChainsList}
            >
              <img
                src={
                  networkInfo[idToNetwork[networkId]].chainName.includes(
                    "TENET"
                  )
                    ? TenetIcon
                    : EthIcon
                }
                alt="network icon"
                className={css.networkImg}
              />
              <ExpandArrow
                className={cn(
                  css.switchChainExpandArrow,
                  !!anchorEl && css.switchChainExpandArrowOpen
                )}
              />
            </div>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              classes={{
                root: css.chainsListWrapperRoot,
                paper: css.chainsListWrapperPaper,
              }}
            >
              <ul className={css.chainList}>
                {Object.entries(networkInfo).map((network, i) => {
                  const isActive =
                    networkId && +network[1].chainId === networkId;
                  return (
                    <li
                      className={css.chainItem}
                      key={i}
                      onClick={() => {
                        if (!isActive && web3Provider) {
                          changeNetworkAtMetamask(
                            web3Provider,
                            idToNetwork[+network[1].chainId],
                            setAlert
                          ).then(() => setAnchorEl(null));
                        }
                      }}
                    >
                      <img
                        src={
                          network[1].chainName.includes("TENET")
                            ? TenetIcon
                            : EthIcon
                        }
                        alt="network icon"
                        className={css.chainItemImg}
                      />
                      <span>{shortenNetworkNames[+network[1].chainId]}</span>
                      {isActive && (
                        <CheckIcon className={css.chainItemCheckIcon} />
                      )}
                    </li>
                  );
                })}
              </ul>
            </Popover>
          </div>
        )}
      </div>
      <div className={css.walletWrapper}>
        {!expanded && (
          <Button
            onClick={() => setIsOpenRafflesDetails(true)}
            className={cn(
              css.btnOpenDrawer,
              account && css.btnOpenDrawerWithWallet
            )}
            text={"Get Tenet"}
            variant={"primary"}
          />
        )}
        {!account ? (
          <Button
            onClick={() => onConnect()}
            className={css.connectWalletBtn}
            text={"Connect wallet"}
            variant={"primary"}
            loading={isConnectingWallet}
          />
        ) : (
          <div className={css.connectedWalletWrapper}>
            <div
              className={css.connectedWalletItem}
              onClick={() => setCopied(account)}
            >
              {shortenAddress(account)}
              <CopyIcon
                className={cn(css.copyIcon, !isCopied && css.copyIconNotCopied)}
                isCopied={isCopied}
              />
            </div>
            <div
              className={cn(
                css.connectedWalletItem,
                css.connectedWalletItemBalance
              )}
              onClick={() => setExpanded((prev) => !prev)}
            >
              <WalletIcon className={css.walletIcon} />
              <span className={css.balanceText}>{userBalance} TENET</span>
              <ExpandArrow
                className={cn(css.expandIcon, expanded && css.expandIconActive)}
              />
              {expanded && (
                <Button
                  className={css.disconnectBtn}
                  onClick={onDisconnect}
                  text={"Disconnect"}
                  variant={"primary"}
                  Icon={<SmartIcon />}
                />
              )}
            </div>
          </div>
        )}
        {account && !isCorrectChainId && (
          <Button
            onClick={() => {
              setAlert({
                severity: "info",
                message:
                  "Please reconnect your account after switching to the correct network",
                shown: true,
              });
              changeNetworkAtMetamask(web3Provider, NETWORK.tenet);
            }}
            className={css.connectWalletBtn}
            text={"Wrong network"}
            variant={"danger"}
            loading={isConnectingWallet}
          />
        )}
      </div>
      <UiDrawer
        className={css.getTenetDrawer}
        onClose={() => setIsOpenRafflesDetails(false)}
        open={isOpenRafflesDetails}
      >
        <GetTenetView />
      </UiDrawer>
    </header>
  );
};
