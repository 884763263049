import React from 'react';

export const RewardIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5003 26C10.1404 26 8.48229 16.6718 8.18855 9.27301C8.10684 7.21496 8.06599 6.18593 8.83903 5.23385C9.61207 4.28177 10.5373 4.12567 12.3877 3.81346C14.2143 3.50527 16.6021 3.25 19.5003 3.25C22.3986 3.25 24.7863 3.50527 26.6129 3.81346C28.4633 4.12567 29.3885 4.28177 30.1616 5.23385C30.9346 6.18593 30.8938 7.21496 30.8121 9.27301C30.5183 16.6718 28.8602 26 19.5003 26Z"
        fill="#73E0A9"
        stroke="#73E0A9"
        strokeWidth="1.5"
      />
      <path
        d="M30.875 8.125L32.4165 8.63884C34.0254 9.17512 34.8298 9.44326 35.2899 10.0816C35.75 10.72 35.7499 11.5679 35.7499 13.2638L35.7499 13.3817C35.7499 14.7804 35.7498 15.4797 35.4132 16.0519C35.0765 16.6241 34.4651 16.9637 33.2425 17.643L28.4375 20.3125"
        stroke="#73E0A9"
        strokeWidth="1.5"
      />
      <path
        d="M8.12491 8.125L6.58339 8.63884C4.97455 9.17512 4.17013 9.44326 3.71003 10.0816C3.24993 10.72 3.24996 11.5679 3.25 13.2638L3.25 13.3817C3.25004 14.7804 3.25006 15.4797 3.58674 16.0519C3.92342 16.6241 4.53477 16.9637 5.75745 17.643L10.5624 20.3125"
        stroke="#73E0A9"
        strokeWidth="1.5"
      />
      <path d="M19.5 27.625V30.875" stroke="#73E0A9" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M25.1875 35.75H13.8125L14.3637 32.9938C14.5157 32.2342 15.1826 31.6875 15.9572 31.6875H23.0428C23.8174 31.6875 24.4843 32.2342 24.6363 32.9938L25.1875 35.75Z"
        stroke="#73E0A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M29.25 35.75H9.75" stroke="#73E0A9" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
