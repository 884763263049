export const ADDRESSES = {
  MERKLE_DISTRIBUTOR1: "0x78C13B8511DCfA8346b3470eA7Defe28d5314D90",
  MERKLE_DISTRIBUTOR2: "0x578F36c012EDa5237f82886A593d92B61F5d418C",
  MERKLE_DISTRIBUTOR3: "0x9Ae5f7639610eF424e1312E422eFAA63549754Cb",
  MERKLE_DISTRIBUTOR4: "0xcf4Cbfac1ead9D6b313150E580a32Dec3A231a57",
  MERKLE_DISTRIBUTOR5: "0xB5df353488637Fb1d2E300A4f6EaD8b914570118",
  MERKLE_DISTRIBUTOR6: "0x2951eF72d192068eA4F69f9EdAFEBDD4949f84f0",
  MERKLE_DISTRIBUTOR7: "0xEc51159cC713E3111Fb6F9a2282eD0Ca98553EB8",
  MERKLE_DISTRIBUTOR8: "0x31A83028584F1b4C838ea63E88e4856E4EeAeD88",
  MERKLE_DISTRIBUTOR9: "0x07d80147a544C596A5759b1Ee5912418667A70Dc",
  MERKLE_DISTRIBUTOR10: "0x4F2b219328A5576fE13f65abDfb7222f26Ed0027",
  MERKLE_DISTRIBUTOR11: "0xF0f16C3B6A7451A1ce1A01Ab51662A427418053d",
  MERKLE_DISTRIBUTOR12: "0x83d415a45e2F9Ae66634Ec43d0384b72313dA4Ed",
  MERKLE_DISTRIBUTOR13: "0x798aaCF19E24dd6B0Fd51Eb01B07b29dBAa052B9",
  MERKLE_DISTRIBUTOR14: "0x5edBB784b8a758db11577621c9c83d8fd4F31646",
  MERKLE_DISTRIBUTOR15: "0xe52CF3d200987f1cd3282E8f666D217293DDc390",
  MERKLE_DISTRIBUTOR16: "0xf442dBcF6fecAAE241Ae2f43fD3ffB6b6BE278f6",
  MERKLE_DISTRIBUTOR17: "0xe53884a8c0E64a528460b1a0A2F0525b2bA2Adc5",
  MERKLE_DISTRIBUTOR18: "0x0ce9F3129f06baf111d6Ad6521Ab3867f4f10A62",
  MERKLE_DISTRIBUTOR19: "0xdE0968D7A0562f98C8208ce6E9edDf77C0aBB96C",
  MERKLE_DISTRIBUTOR20: "0xfC61Bf9c212B217F7ac802C7e39FD756A22a7fa0",
  MERKLE_DISTRIBUTOR21: "0xF7c74D6E3e3991261Da7f06D6862B9461De1B35c",
  MERKLE_DISTRIBUTOR22: "0x8e0778105836c6f647cB7fA72366478b4a0069A9",
  MERKLE_DISTRIBUTOR23: "0x98d7f52Db7117fcf7bF330E5da53F1795f1e776E",
  MERKLE_DISTRIBUTOR24: "0xbAf1d3849932b14bA28F2E044e346a06a8AcF583",
  MERKLE_DISTRIBUTOR25: "0xa8bA6653855e3390B8858B4cadac96e294933842",
  MERKLE_DISTRIBUTOR26: "0x67DF5d21ea956c88E7cf40895C27C06553D9aFB3",
  MERKLE_DISTRIBUTOR27: "0x708941b7eb124c9D9321288fa6C2Bea083Ecab5E",
};

export const winnersUrls = [
  "https://drive.google.com/file/d/1VXDoHSKghMwqI_8iAba3nipjnDM-NSHT/view",
];

export const INITIAL_RAFFLE_DATE = new Date(Date.UTC(2023, 5, 14, 12));

export const calculateRaffleDate = (epochIndex: number) => {
  const daysCount = epochIndex <= 0 ? 0 : epochIndex * 14;
  const newRaffleDate = new Date(INITIAL_RAFFLE_DATE);
  newRaffleDate.setDate(newRaffleDate.getDate() + daysCount);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  };
  return newRaffleDate
      .toLocaleString("en-US", options)
      .toUpperCase();
};

export const contractAddresses: string[] = Object.keys(ADDRESSES).map(key => ADDRESSES[key as keyof typeof ADDRESSES]);
export const lotteryHeldDate = new Date(Date.UTC(2023, 5, 14, 12));

// 1st october & 8th october, disabled all buttons
export const isTechnicalWorks = false;
// 8 oct & 16 oct show green background
export const successTechnicalWorks = true;
// to 16th October show global disclaimer
export const showDisclaimer = false;

// export const isTechnicalWorks = Date.now() > 1664625600000 && Date.now() < 1665230400000;// 1st october & 8th october
// export const successTechnicalWorks = Date.now() > 1665230400000 && Date.now() < 1665921600000; // 8 oct & 16 oct
// export const showDisclaimer = Date.now() < 1665921600000; // 16th October
