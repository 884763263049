import React from "react";
import css from "./styles.module.scss";
import { RaffleItem } from "./components/RaffleItem";
import { useWeb3Connect } from "../../contexts/Web3ProviderContext";
import {
  calculateRaffleDate,
  contractAddresses,
  winnersUrls,
} from "../../constants/raffles";
import useStakingStore from "../Staking/store";

export const RafflesView = () => {
  const { raffles, isLastEpochRewardsHidden, untilRaffleDate } = useStakingStore();
  const { account, web3Provider, isEthChainConnected } = useWeb3Connect();

  const previousRaffleRevealTime = new Date(untilRaffleDate!.getTime() - 86400000 * 12);
  const lastRaffleRevealTime = new Date(untilRaffleDate!.getTime() + 86400000 * 2);
  return (
    <div className={css.rafflesWrapper}>
      {raffles &&
        Array(+raffles)
          .fill("0")
          .map((_, i) => i + 1)
          .reverse()
          .map((arrEpochIndex) => (
            <RaffleItem
              key={arrEpochIndex}
              web3={web3Provider}
              currentAddress={account}
              epochIndex={arrEpochIndex}
              snapshotDate={calculateRaffleDate(arrEpochIndex - 1)}
              contractAddress={contractAddresses[arrEpochIndex - 1]}
              winnerUrl={winnersUrls[arrEpochIndex - 1]}
              hideRewards={arrEpochIndex === +raffles ? true : (isLastEpochRewardsHidden && arrEpochIndex === +raffles - 1)} // hide last epoch and hide previous epoch if state required
              lotteryHeldDate={arrEpochIndex === +raffles - 1 ? previousRaffleRevealTime : lastRaffleRevealTime}
              timeout={raffles ? (+raffles - arrEpochIndex) * 1000 : 0}
              isEthChainConnected={isEthChainConnected!}
            />
          ))}
    </div>
  );
};
