import React from 'react';

export const TgIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7106 0.654509C19.7106 0.654509 21.6531 -0.102992 21.4906 1.73651C21.4371 2.49401 20.9516 5.14551 20.5736 8.01301L19.2786 16.508C19.2786 16.508 19.1706 17.7525 18.1991 17.969C17.2281 18.185 15.7711 17.2115 15.5011 16.995C15.2851 16.8325 11.4541 14.3975 10.1051 13.2075C9.72714 12.8825 9.29514 12.2335 10.1591 11.476L15.8246 6.06501C16.4721 5.41601 17.1196 3.90101 14.4216 5.74051L6.86664 10.8805C6.86664 10.8805 6.00314 11.422 4.38464 10.935L0.876647 9.85251C0.876647 9.85251 -0.418353 9.04101 1.79415 8.22951C7.19064 5.68651 13.8281 3.08951 19.7096 0.654509H19.7106Z"
        fill="#E2E8F1"
      />
    </svg>
  );
};
