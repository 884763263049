import React, { useEffect, useRef, useState } from "react";
import css from "../styles.module.scss";
import cn from "classnames";
import { timer } from "../../../utils/timer";
import { StatusStates } from "../../../types/enums";
import { LOOT_BOX, RAFFLE_RESULT } from "../../../constants/mockups";
import Button from "../../../UI-core/Buttton";
import { IProvider, UserProofsType } from "../../../types/interfaces";
import MERKLE_DISTRIBUTOR from "../../../contracts/merkleDistributor/MerkleDistributor.json";
import { getUserInformationAndProofs, UserInformationType } from "../../../api";
import { floorNumber } from "../../../utils/bigNumber";
import CircularProgress from "@mui/material/CircularProgress";
import { changeNetworkAtMetamask, NETWORK } from "../../../utils/network";
import { useWeb3ModalSigner } from "@web3modal/ethers5/react";
import { ethers } from "ethers";

interface PropsTypes {
  winnersListLink?: string;
  epochIndex?: number;
  web3: IProvider | undefined;
  currentAddress?: string;
  snapshotDate: string;
  lotteryHeldDate: Date;
  hideRewards?: boolean;
  winnerUrl?: string;
  contractAddress: string;
  timeout?: number;
  isEthChainConnected: boolean;
}

export const RaffleItem = ({
  lotteryHeldDate,
  currentAddress,
  contractAddress,
  snapshotDate,
  timeout,
  web3,
  hideRewards,
  epochIndex,
  isEthChainConnected,
}: PropsTypes) => {
  const [tickTimer, setTickTimer] = useState<string>();
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const [stakerReward, setStakerReward] = useState<number>();
  const [tickets, setTickets] = useState(0);
  const [userProofs, setUserProofs] = useState<
    UserProofsType | undefined | null
  >(null);
  const [isClaimed, setIsClaimed] = useState<boolean | undefined>(undefined);
  const [merkleContract, setMerkleContract] = useState<
    ethers.Contract | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { signer } = useWeb3ModalSigner();

  const status =
    hideRewards && stakerReward === undefined
      ? StatusStates.WAITING
      : userProofs?.amount && userProofs.amount > 0 && !hideRewards
      ? StatusStates.WIN
      : !hideRewards &&
        (userProofs?.amount === 0 ||
          userProofs?.amount === undefined ||
          userProofs)
      ? StatusStates.LOSE
      : !hideRewards &&
        isLoading &&
        userProofs === null &&
        isClaimed === undefined
      ? StatusStates.LOADING
      : StatusStates.WAITING;
  //const status = StatusStates.WAITING;

  const onUpdate = async () => {
    if (currentAddress && epochIndex !== undefined) {
      setIsLoading(true);

      const res = await getUserInformationAndProofs(epochIndex, currentAddress);
      if (res === undefined) {
        setStakerReward(undefined);
        setTickets(0);
        setUserProofs(undefined);
        setIsLoading(false);
        return;
      }
      const { proofs, amount, realTickets, index, floorTickets } = res;
      const information: UserInformationType = { floorTickets, realTickets };
      setStakerReward(
        information ? floorNumber(+information?.realTickets) : undefined
      );
      setTickets(Math.floor(+information?.floorTickets || 0));
      setUserProofs({ proofs, amount, index });
      if (amount && !hideRewards && signer) {
        const newMerkleContract = new ethers.Contract(
          contractAddress,
          MERKLE_DISTRIBUTOR as any,
          signer
        );
        setMerkleContract(newMerkleContract);
        setIsClaimed(await newMerkleContract.isClaimed(index));
      }

      setIsLoading(false);
    }
    // const proofs = {
    //     amount: 15,
    //     index: 2,
    //     proofs: [""],
    // };
  };

  useEffect(() => {
    if (epochIndex !== undefined) {
      setTimeout(onUpdate, timeout);
    }
  }, [currentAddress, epochIndex]);

  const handleClaim = async () => {
    if (userProofs && merkleContract) {
      try {
        await merkleContract.claim(
          userProofs.index,
          currentAddress,
          userProofs.amount,
          userProofs.proofs,
          { from: currentAddress }
        );
      } catch (e) {
        console.error(e);
      }
      await onUpdate();
    }
  };
  const handleChangeNetwork = async () => {
    try {
      await changeNetworkAtMetamask(web3, NETWORK.tenet);
    } catch (e) {
      console.error(e);
    }
    await onUpdate();
  };

  // const handleOpenList = () => {
  //   window.open(winnerUrl, "_blank");
  // };

  useEffect(() => {
    if (status === StatusStates.WAITING) {
      intervalIdRef.current = setInterval(() => {
        const timerDate = timer(lotteryHeldDate, false);
        if (!Array.isArray(timerDate)) {
          setTickTimer(timerDate);
        }
      }, 1000);
    }
  }, []);

  if (status === StatusStates.LOADING) {
    return (
      <div className={css.loaderWrapper}>
        <CircularProgress
          size={40}
          classes={{
            colorPrimary: css.loaderColor,
          }}
        />
        <p className={css.loaderText}>
          Please connect your wallet to see whether you have won.
        </p>
      </div>
    );
  }

  return (
    <div
      className={cn(css.raffleItemWrapper, {
        [css.raffleItemWaitingWrapper]: status === StatusStates.WAITING,
      })}
    >
      <div className={css.raffleTop}>
        <span className={css.raffleNumber}>
          {status === StatusStates.WAITING && "Current "}Raffle #{epochIndex}
        </span>
        <span className={css.timer}>
          {status === StatusStates.WAITING ? tickTimer : snapshotDate}
        </span>
      </div>
      <p className={css.raffleResultLabel}>
        {RAFFLE_RESULT[status].icon}
        <span className={css.rafResText}>{RAFFLE_RESULT[status].text}</span>
      </p>
      {status !== StatusStates.WAITING ? (
        <>
          {status === StatusStates.WIN ? (
            //<img className={css.lootBoxImg} src={LOOT_BOX[epochIndex]} alt="lootbox" />
            <img className={css.lootBoxImg} src={LOOT_BOX[1]} alt={"lootbox"} />
          ) : (
            <div style={{ height: 150 }} />
          )}
          <div className={css.notWaitingWrapper}>
            <p className={css.raffleCalmText}>
              {status === StatusStates.WIN
                ? `Your staked asset brought you ${tickets} entries. You’ve won this raffle round, congratulations!`
                : `You had ${tickets} entries, but unfortunately did not win this raffle round`}
            </p>
            <div className={css.btnWrapper}>
              {status === StatusStates.WIN && !isEthChainConnected ? (
                <Button
                  disabled={isClaimed}
                  text={"Claim reward"}
                  variant={"primary"}
                  className={css.viewListBtn}
                  onClick={handleClaim}
                />
              ) : isEthChainConnected ? (
                <Button
                  text={"Change network"}
                  variant={"primary"}
                  className={css.viewListBtn}
                  onClick={handleChangeNetwork}
                />
              ) : (
                <></>
              )}
              {/*<Button
                text={"View winner list"}
                variant={"primaryBorder"}
                link={winnersListLink || "#"}
                className={css.viewListBtn}
                onClick={handleOpenList}
              />*/}
            </div>
          </div>
        </>
      ) : (
        <div className={css.notWaitingWrapper} style={{ marginTop: 80 }}>
          <p
            className={css.raffleCalmText}
            style={{ maxWidth: 290, width: "100%", lineHeight: "17.5px" }}
          >
            During this raffle your staked assets brought you {tickets} entries!
          </p>
        </div>
      )}
    </div>
  );
};
