import React from 'react';

export const LinkIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="51"
      height="49"
      viewBox="0 0 51 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="14.2904" y1="36.5961" x2="34.4499" y2="16.2957" stroke="black" strokeWidth="2" />
      <line x1="38.9805" y1="41.5996" x2="38.9805" y2="14.9996" stroke="black" strokeWidth="2" />
      <line x1="9" y1="11" x2="35.6" y2="11" stroke="black" strokeWidth="2" />
    </svg>
  );
};
