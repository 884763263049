import { useEffect, useState } from "react";

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { IProviderOptions } from "web3modal";
import {
  changeNetworkAtMetamask,
  checkSupportedChainId,
  idToNetwork,
  NETWORK,
  networkInfo,
  networkToId,
  NetworkType,
} from "../utils/network";
import { useToaster } from "../modules/Toaster/ToasterContext";
import { useHandleError } from "./useHandleError";
import useAppStore from "../stores/appStore";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalSigner,
} from "@web3modal/ethers5/react";
import { IProvider } from "../types/interfaces";

const projectId = "d10ab241461c2b37901326c45d3a3a62";

const eth = networkInfo[NETWORK.ethereum];
const tenet = networkInfo[NETWORK.tenet];

const EthereumNetwork = {
  chainId: networkToId[NETWORK.ethereum],
  name: eth.chainName,
  currency: eth.nativeCurrency.symbol,
  explorerUrl: eth.blockExplorerUrls[0],
  rpcUrl: eth.rpcUrls[0],
};

const TenetNetwork = {
  chainId: networkToId[NETWORK.tenet],
  name: tenet.chainName,
  currency: tenet.nativeCurrency.symbol,
  explorerUrl: tenet.blockExplorerUrls[0],
  rpcUrl: tenet.rpcUrls[0],
};

const metadata = {
  name: "Tenet Eva Staking",
  description: "Stake your TENET",
  url: "https://tenet.org/",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

export const web3ModalConfig = {
  metadata,
  projectId,
  chains: [EthereumNetwork, TenetNetwork],
};

const useWeb3Provider = () => {
  const providerOptions: IProviderOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          [networkInfo[NETWORK.tenet].chainId]:
            networkInfo[NETWORK.tenet].rpcUrls[0],
        },
      },
    },
  };
  // const web3Modal = new Web3Modal({ network: NETWORK.stepnet, cacheProvider: true, providerOptions });

  const [web3Provider, setWeb3Provider] = useState<IProvider | undefined>(
    undefined
  );
  const [account, setAccount] = useState<string | undefined>(undefined);
  const [isCorrectChainId, setIsCorrectChainId] = useState<boolean | undefined>(
    undefined
  );
  const { setAlert } = useToaster();
  const handleError = useHandleError();
  const [networkId, setNetworkId] = useState<number | undefined>(undefined);
  const [currencySymbol, setCurrencySymbol] = useState<string>("TENET");
  const [isEthChainConnected, setIsEthChainConnected] = useState<
    boolean | undefined
  >(undefined);

  const { open } = useWeb3Modal();
  const {
    isConnected,
    address,
    chainId: providerChainId,
  } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalSigner();

  const onDisconnect = async () => {
    if (isConnected && walletProvider) {
      await open();
      /*setAccount(undefined);
      localStorage.removeItem("wallet");
      setAlert({
        shown: true,
        message: "The wallet was disconnected",
        severity: "warning",
      });*/
    }
  };

  const subscribeProvider = async (newProvider: IProvider | undefined) => {
    if (!newProvider) {
      return;
    }

    newProvider.on("eth_accounts", () => {
      setAlert({
        shown: true,
        message: "The wallet was successfully connected",
        severity: "success",
      });
    });
    newProvider.on("close", () => {
      onDisconnect();
    });
    newProvider.on("accountsChanged", async (accounts: string[]) => {
      setAccount(accounts[0]);
    });
    newProvider.on("chainChanged", async (hexChainId: string) => {

      const chainId = Web3.utils.hexToNumber(hexChainId ?? "");
      if (
        chainId === +networkToId[NETWORK.tenet] ||
        chainId === +networkToId[NETWORK.ethereum]
      ) {
        await onConnect();
        checkSupportedChainId(chainId) && setNetworkId(chainId);
      } else {
        setIsCorrectChainId(false);
      }
    });
  };

  useEffect(() => {
    if (walletProvider) {
      subscribeProvider(walletProvider);
      setWeb3Provider(walletProvider);
      const savedNetwork = localStorage.getItem("network");
      changeNetworkAtMetamask(
        walletProvider,
        savedNetwork ? NETWORK[savedNetwork as NetworkType] : NETWORK.tenet
      );
    }
  }, [walletProvider]);

  useEffect(() => {
    if (providerChainId) {
      setNetworkId(providerChainId);

      checkSupportedChainId(providerChainId) && setNetworkId(providerChainId);
      setIsCorrectChainId(
        providerChainId === +networkToId[NETWORK.tenet] ||
          providerChainId === +networkToId[NETWORK.ethereum]
      );
    }
  }, [providerChainId]);

  useEffect(() => {
    setAccount(address);
    if (address) {
      localStorage.setItem("wallet", address);
    }
  }, [address]);

  useEffect(() => {
    if (networkId) {
      setCurrencySymbol(
        networkInfo[idToNetwork[networkId]]?.nativeCurrency?.symbol
      );
      setIsEthChainConnected(
        networkInfo[idToNetwork[networkId]]?.nativeCurrency?.symbol === "ETH"
      );
    }
  }, [networkId]);

  const onConnect = async (isOnRefresh = false) => {
    try {
      /* if (!window.ethereum) {
        window.open(`https://metamask.app.link/dapp/${window.location.href}`);
        return;
      }*/
      useAppStore.setState({ isConnectingWallet: true });
      await open();
      const isWalletConnected = localStorage.getItem("wallet");
      /* if (isWalletConnected || !isOnRefresh) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      }*/
    } catch (e) {
      handleError(e, "Failed to connect wallet");
    } finally {
      useAppStore.setState({ isConnectingWallet: false });
    }
  };

  return {
    provider: web3Provider,
    account,
    isCorrectChainId,
    onConnect,
    onDisconnect,
    networkId,
    currencySymbol,
    isEthChainConnected,
  };
};

export default useWeb3Provider;
