import { ContractInterface, ethers } from 'ethers';
import { useMemo } from 'react';
import { NETWORK, networkInfo } from '../utils/network';
import {useWeb3Connect} from "../contexts/Web3ProviderContext";

export const useEthersContract = (address: string | undefined, ABI: ContractInterface) => {
  const {isEthChainConnected} = useWeb3Connect()
  return useMemo(() => {
    if (!address || !ABI) return null;
    try {
      return new ethers.Contract(
        address,
        ABI,
        new ethers.providers.JsonRpcProvider(networkInfo[isEthChainConnected ? NETWORK.ethereum : NETWORK.tenet].rpcUrls[0])
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, isEthChainConnected]);
};
