import React from 'react';

export const SadSmile = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="203"
      height="203"
      viewBox="0 0 203 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.917 101.5C16.917 61.6271 16.917 41.6906 29.3039 29.3037C41.6909 16.9167 61.6274 16.9167 101.5 16.9167C141.373 16.9167 161.31 16.9167 173.697 29.3037C186.084 41.6906 186.084 61.6271 186.084 101.5C186.084 141.373 186.084 161.31 173.697 173.696C161.31 186.083 141.373 186.083 101.5 186.083C61.6274 186.083 41.6909 186.083 29.3039 173.696C16.917 161.31 16.917 141.373 16.917 101.5Z"
        stroke="#FF6969"
        strokeWidth="1.5"
      />
      <path
        d="M67.667 101.5L84.5837 88.8125L67.667 76.125"
        stroke="#FF6969"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.333 101.5L118.416 88.8125L135.333 76.125"
        stroke="#FF6969"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.333 135.333L124.055 126.875L112.777 135.333L101.5 126.875L90.2219 135.333L78.9441 126.875L67.6663 135.333"
        stroke="#FF6969"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
