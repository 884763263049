import {ethers} from "ethers";

const SECOND = 1;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const getPaddedTime = (time: number) => (time > 9 ? time : `0${time}`);

export const getTimeLeft = (seconds: number) => {
    const d = Math.floor(seconds / DAY);
    const h = Math.floor((seconds - d * DAY) / HOUR);
    const m = Math.floor((seconds - d * DAY - h * HOUR) / MINUTE);
    const s = Math.floor((seconds - d * DAY - h * HOUR - m * MINUTE) / SECOND);

    return `${getPaddedTime(d)}:${getPaddedTime(h)}:${getPaddedTime(m)}:${getPaddedTime(s)}`;
};

export const getRewardPerDay = (n: ethers.BigNumber) => n.mul(DAY);
