import React from 'react';
import css from './styles.module.scss';
import { SuccessSmile } from '../../assets/svgComponents/SuccessSmile';
import Button from '../Buttton';

interface PropsTypes {
  title: string;
  subtitle: string;
  onClose: () => void;
}

export const SuccessStakeUnstake = ({ title, subtitle, onClose }: PropsTypes) => {
  return (
    <div className={css.successWrapper}>
      <div />
      <SuccessSmile className={css.successSmile} />
      <div className={css.successContentWrapper}>
        <h3 className={css.successContentTitle}>{title}</h3>
        <p className={css.successContentSubtitle}>{subtitle}</p>
        <Button text={'Excellent!'} variant={'primary'} className={css.excellentBtn} onClick={onClose} />
      </div>
    </div>
  );
};
