import React, { useEffect, useRef, useState } from "react";
import css from "../styles.module.scss";
import cn from "classnames";
import { ArrowIcon } from "../../../assets/svgComponents/ArrowIcon";
import { timer } from "../../../utils/timer";
import UiDrawer from "../../../UI-core/Drawer/inex";
import { RafflesView } from "../../Raffles";
import useStakingStore from "../store";
import CircularProgress from "@mui/material/CircularProgress";
import { Tooltip } from "@mui/material";
import { toNormalFixed } from "../../../utils";
import Button from "../../../UI-core/Buttton";
import { getIfShowRewards } from "../../../api";

export const InfoBlock = () => {
  const [isOpenRafflesDetails, setIsOpenRafflesDetails] =
    useState<boolean>(false);
  const {
    isGettingInfo,
    untilRaffleDate,
    raffles,
    entriesPerDay,
    totalEntries,
    isLastEpochRewardsHidden,
  } = useStakingStore();
  const [tickTimer, setTickTimer] = useState([
    { label: "d", value: "00" },
    { label: "h", value: "00" },
    { label: "m", value: "00" },
    { label: "s", value: "00" },
  ]);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (untilRaffleDate) {
      intervalIdRef.current = setInterval(() => {
        //untilRaffleDate
        const timerDate = timer(untilRaffleDate, true);
        if (Array.isArray(timerDate)) {
          setTickTimer(timerDate);
        }
      }, 1000);
    }
  }, [untilRaffleDate]);

  useEffect(() => {
    getIfShowRewards().then((res) => {
      useStakingStore.setState({ isLastEpochRewardsHidden: !!res });
    });
  }, []);

  const renderTimer = () => {
    return tickTimer.map((item, i) => (
      <span key={i}>
        <span className={css.infoValue}>{item.value}</span>
        <span className={css.infoCurrency}>{item.label}</span>
      </span>
    ));
  };
  return (
    <div className={css.infoBlocks}>
      <div className={cn(css.infoBlockItem, css.infoBlockItemEntries)}>
        {!isGettingInfo ? (
          <div className={css.infoItemTop}>
            <div className={css.infoText}>
              <Tooltip
                title={totalEntries > 0 ? totalEntries : ""}
                placement="top"
              >
                <span className={css.infoValue}>{totalEntries}</span>
              </Tooltip>
              <span className={css.infoCurrency}>Entries</span>
            </div>
          </div>
        ) : (
          <div className={css.loaderWrapper}>
            <CircularProgress
              size={40}
              classes={{
                colorPrimary: css.loaderColor,
              }}
            />
          </div>
        )}
        <div className={css.infoItemBottom}>
          <span className={css.infoLabel}>Total Entries</span>
        </div>
      </div>
      <div className={cn(css.infoBlockItem, css.infoBlockItemPerDay)}>
        {!isGettingInfo ? (
          <div className={css.infoItemTop}>
            <div className={css.infoText}>
              <Tooltip
                title={entriesPerDay > 0 ? entriesPerDay : ""}
                placement="top"
              >
                <span className={css.infoValue}>
                  +{toNormalFixed(entriesPerDay, 2)}
                </span>
              </Tooltip>
              <span className={css.infoCurrency}>Entries</span>
            </div>
          </div>
        ) : (
          <div className={css.loaderWrapper}>
            <CircularProgress
              size={40}
              classes={{
                colorPrimary: css.loaderColor,
              }}
            />
          </div>
        )}
        <div className={css.infoItemBottom}>
          <span className={css.infoLabel}>Per day</span>
        </div>
      </div>
      <div
        className={cn(css.infoBlockItem, css.infoBlockItemRaffles)}
        onClick={() => !isGettingInfo && setIsOpenRafflesDetails(true)}
      >
        {!isGettingInfo ? (
          <div className={css.infoItemTop}>
            <div className={css.infoText}>
              <Tooltip title={""} placement="top">
                <span className={css.infoValue}>{raffles}</span>
              </Tooltip>
              <span className={css.infoCurrency}></span>
            </div>
            {isLastEpochRewardsHidden ? (
              <Button
                text={"In progress"}
                variant={"primary"}
                className={css.progressBtn}
              />
            ) : (
              <Button
                text={"Soon"}
                variant={"primary"}
                className={cn(css.wonBtn)}
              />
            )}
          </div>
        ) : (
          <div className={css.loaderWrapper}>
            <CircularProgress
              size={40}
              classes={{
                colorPrimary: css.loaderColor,
              }}
            />
          </div>
        )}
        <div className={css.infoItemBottom}>
          <span className={css.infoLabel}>Raffles</span>
          <div className={css.showPrevWrapper}>
            {isLastEpochRewardsHidden && <span>Show previous</span>}
            <ArrowIcon className={css.arrowIcon} />
          </div>
        </div>
      </div>
      <div className={cn(css.infoBlockItem, css.infoBlockItemUntilRaffle)}>
        {!isGettingInfo ? (
          <div className={css.infoItemTop}>
            <div className={css.infoText}>{renderTimer()}</div>
          </div>
        ) : (
          <div className={css.loaderWrapper}>
            <CircularProgress
              size={40}
              classes={{
                colorPrimary: css.loaderColor,
              }}
            />
          </div>
        )}
        <div className={css.infoItemBottom}>
          <span className={css.infoLabel}>Until Raffle</span>
        </div>
      </div>
      <UiDrawer
        onClose={() => setIsOpenRafflesDetails(false)}
        open={isOpenRafflesDetails}
      >
        <RafflesView />
      </UiDrawer>
    </div>
  );
};
