import { create } from "zustand";
import { CurrentEpochDataType, StakeDataType } from "../../types/interfaces";
export interface IStakingStore {
  totalEntries: number;
  entriesPerDay: number;
  raffles: string;
  untilRaffleDate: Date | undefined;
  isStaking: boolean;
  isConnecting: boolean;
  unstaking: {
    loading: boolean;
    id: number;
  };
  isGettingInfo: boolean;
  penaltyDays: number;
  gettingWithdraws: boolean;
  stakedBalance: string;
  isLastEpochRewardsHidden: boolean;
  newEpoch: CurrentEpochDataType | undefined;
  stakers: StakeDataType[];
  stakerStakeCount: number;
  maxBPS: number;
  income: number;
  setIncome: (income: IStakingStore["income"]) => void;
  handleStakersAfterUnstake: (id: number) => void;
  isUnstakedCanceled: boolean;
  setIsUnstakedCanceled: (isUnstakedCanceled: boolean) => void;
}

// @ts-ignore
const useStakingStore = create<IStakingStore>()((set, get) => {
  return {
    totalEntries: "0",
    entriesPerDay: 0,
    penaltyDays: 0,
    income: 0,
    raffles: "0",
    untilRaffleDate: undefined,
    isStaking: false,
    isConnecting: false,
    isGettingInfo: false,
    isLastEpochRewardsHidden: true,
    unstaking: {
      loading: false,
      id: -1,
    },
    gettingWithdraws: false,
    isUnstakedCanceled: false,
    stakedBalance: "0",
    newEpoch: undefined,
    setIncome: (income) => set({ income }),
    setIsUnstakedCanceled: (isUnstakedCanceled) => {
      set({ isUnstakedCanceled });
    },
    stakers: [],
    stakerStakeCount: 0,
    maxBPS: 0,
    handleStakersAfterUnstake: (id) => {
      const stakers = get().stakers;
      const updatedStakers = stakers.map((staker) =>
        staker.id === id ? { ...staker, unstaked: true } : staker
      );
      set({ stakers: updatedStakers });
    },
  };
});

export default useStakingStore;
