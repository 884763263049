import React from 'react';

export const SuccessSmile = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="217"
      height="217"
      viewBox="0 0 217 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.585 144.062C91.9625 146.913 104.87 146.915 117.859 143.435C130.848 139.954 142.026 133.499 150.454 125.341"
        stroke="#73E0A9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <ellipse
        cx="131.189"
        cy="88.3781"
        rx="9.04167"
        ry="13.5625"
        transform="rotate(-15 131.189 88.3781)"
        fill="#73E0A9"
      />
      <ellipse
        cx="78.7897"
        cy="102.422"
        rx="9.04167"
        ry="13.5625"
        transform="rotate(-15 78.7897 102.422)"
        fill="#73E0A9"
      />
      <path
        d="M28.9652 129.811C18.919 92.3183 13.8959 73.5718 22.4225 58.8033C30.9491 44.0348 49.6956 39.0117 87.1885 28.9655C124.681 18.9193 143.428 13.8962 158.196 22.4228C172.965 30.9494 177.988 49.6959 188.034 87.1888C198.08 124.682 203.103 143.428 194.577 158.197C186.05 172.965 167.304 177.988 129.811 188.034C92.318 198.081 73.5715 203.104 58.803 194.577C44.0345 186.051 39.0114 167.304 28.9652 129.811Z"
        stroke="#73E0A9"
        strokeWidth="1.5"
      />
      <path
        d="M117.542 144.666L121.864 153.471C125.131 160.127 133.138 162.925 139.84 159.752C146.677 156.516 149.544 148.31 146.211 141.52L142.059 133.06"
        stroke="#73E0A9"
        strokeWidth="1.5"
      />
    </svg>
  );
};
