import React from 'react';

export const ExpandArrow = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9.83203"
        y="5.13867"
        width="6.49493"
        height="0.667175"
        transform="rotate(135 9.83203 5.13867)"
        fill="white"
      />
      <rect
        x="1.08984"
        y="4.63464"
        width="6.09455"
        height="0.669929"
        transform="rotate(45 1.08984 4.63464)"
        fill="white"
      />
    </svg>
  );
};
