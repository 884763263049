import React, { createContext, useContext, useState } from 'react';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';

type TransitionProps = Omit<SlideProps, 'direction'>;

interface ProviderProps {
  children: React.ReactNode;
}

interface UIContextProps {
  setAlert: React.Dispatch<React.SetStateAction<{ severity?: AlertColor; shown: boolean; message?: string }>>;
  alert: { severity?: AlertColor; shown: boolean; message?: string };
}

export const UiToaster = createContext<UIContextProps>({} as UIContextProps);
export const useToaster = () => useContext(UiToaster);

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

export const ToasterContextProvider = ({ children }: ProviderProps) => {
  const [alert, setAlert] = useState<{ severity?: AlertColor; shown: boolean; message?: string }>({
    severity: 'info',
    shown: false,
    message: '',
  });

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({
      ...alert,
      shown: false,
    });
  };

  return (
    <UiToaster.Provider value={{ setAlert, alert }}>
      {children}
      <Snackbar
        TransitionComponent={TransitionRight}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={alert.shown}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </UiToaster.Provider>
  );
};
