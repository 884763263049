import React, { useEffect, useState } from "react";
import css from "./styles.module.scss";
import { HowItWorksTitle } from "../../assets/svgComponents/HowItWorksTitle";
import { useWeb3Connect } from "../../contexts/Web3ProviderContext";
import "swiper/css";
import cn from "classnames";
import { HOW_IT_WORKS_ITEMS } from "../../constants/mockups";
import { InView, useInView } from "react-intersection-observer";
import useWindowSize from "../../hooks/useWindowSize";
import { MAX_MOBILE_WIDTH } from "../../constants";

export const HowItWorksView = () => {
  const { currencySymbol } = useWeb3Connect();
  const { ref, inView } = useInView();
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [isBlockInView, setIsBlockInView] = useState<boolean>(false);
  const { width } = useWindowSize();
  useEffect(() => {
    inView && setIsBlockInView(true);
  }, [inView]);

  return (
    <section className={css.howItWorksWrapper}>
      <HowItWorksTitle
        className={cn(
          css.howItWorksTitleBig,
          inView && css.howItWorksTitleBigActive
        )}
      />
      <h2 className={css.howItWorksTitle} ref={ref}>
        How it works
      </h2>
      <div
        className={cn(
          css.howItWorksInner,
          width > MAX_MOBILE_WIDTH && !isBlockInView && css.scrolled
        )}
      >
        {/*<ul className={css.scrollProgressWrapper}>
          {new Array(HOW_IT_WORKS_ITEMS.length).fill(1).map((item, i) => (
            <li
              key={i}
              className={cn(
                css.scrollProgressItem,
                viewIndex === i && css.scrollProgressItemActive
              )}
            />
          ))}
        </ul>*/}
        <ul className={css.howItWorksBody}>
          {HOW_IT_WORKS_ITEMS.map((item, i) => {
            return (
              <li
                key={i}
                className={cn(
                  css.howItWorksItemBody,
                  i % 2 !== 0 && css.howItWorksItemBodyEven
                )}
              >
                <div className={css.howItWorksBodyText}>
                  <h3
                    className={cn(css.howItWorksSubTitle, {
                      [css.green]: i == 0,
                      [css.red]: i == 1,
                      [css.blue]: i == 2,
                      [css.yellow]: i == 3,
                    })}
                  >
                    {item.title}
                  </h3>
                  <p className={css.howItWorksParagraph}>
                    {item.text.map((item, i) => (
                      <span key={i}>
                        {item}
                        <br />
                      </span>
                    ))}
                  </p>
                  <InView
                    as={"span"}
                    onChange={(inView, entry) => {
                      inView && setViewIndex(i);
                    }}
                  />
                </div>
                <div className={css.howItWorksImgWrapper}>
                  <img src={item.img} alt="" className={css.howItWorksImg} />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
