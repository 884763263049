import React from 'react';

export const CheckIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9643 16.5859C10.1833 17.3669 8.91693 17.3669 8.13588 16.5859L4.5626 13.0126C4.16909 12.6191 4.16909 11.9811 4.5626 11.5876C4.9561 11.1941 5.59409 11.1941 5.9876 11.5876L8.13588 13.7359C8.91693 14.5169 10.1833 14.5169 10.9643 13.7359L18.0126 6.6876C18.4061 6.2941 19.0441 6.29409 19.4376 6.6876C19.8311 7.0811 19.8311 7.71909 19.4376 8.1126L10.9643 16.5859Z"
        fill="#74DFA8"
      />
    </svg>
  );
};
