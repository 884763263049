import React from 'react';
import css from './styles.module.scss';
import cn from 'classnames';
import { LinkIcon } from '../../assets/svgComponents/LinkIcon';
import { HEADER_NAV, HEADER_SOCIALS } from '../../constants/mockups';
import { Logo } from '../../assets/svgComponents/Logo';

export const FooterView = () => {
  return (
    <footer className={css.footer}>
      <div className={css.footerBody}>
        <div className={css.footerTop}>
          <a target={'_blank'} href="https://partner.bybit.com/b/trade_tenet" className={cn(css.linkItem, css.linkItemSigns)}>
            <LinkIcon className={css.linkIcon} />
            <h4 className={css.linkTitle}>Trade $TENET on Bybit</h4>
            {/*<p className={css.linkSubtitle}>86,432 signs</p>*/}
          </a>
          <a target={'_blank'} href="https://tenet.org/eva" className={cn(css.linkItem, css.linkItemTickets)}>
            <LinkIcon className={css.linkIcon} />
            <h4 className={css.linkTitle}>Earn tickets on Eva App</h4>
          </a>
        </div>
        <div className={css.footerBottom}>
          <div className={css.linksAndLogoWrapper}>
            <Logo className={css.footerLogo} />
            <nav className={css.footerNav}>
              <div className={css.navLeft}>
                {HEADER_NAV.slice(0, 3).map((item, i) => (
                  <a key={i} className={css.footerNavItem} target={'_blank'} href={item.link}>
                    {item.text}
                  </a>
                ))}
              </div>
              <div className={css.navRight}>
                {HEADER_NAV.slice(3).map((item, i) => (
                  <a key={i} className={css.footerNavItem} target={'_blank'} href={item.link}>
                    {item.text}
                  </a>
                ))}
              </div>
            </nav>
          </div>
          <div className={css.socialsAndLegal}>
            <div className={css.divider} />
            <div className={css.divider} />
            <p className={css.footerLegal}>2023 ALL RIGHT RESERVED</p>
            <ul className={css.socialsList}>
              {HEADER_SOCIALS.map((item, i) => (
                <li key={i} className={css.socialsItem}>
                  <a href={item.link} target={'_blank'}>
                    <item.logo className={css.socialsItemLogo} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
