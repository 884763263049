import { useCallback } from "react";
import useAppStore from "../../stores/appStore";
import {
  TENET_STAKING_ADDRESS,
  TENET_STAKING_ADDRESS_ETH,
} from "../../contracts/tenetStaking";
import StakingAbi from "../../contracts/tenetStaking/TenetStakingNative.json";
import {
  TENET_CALCULATOR_ADDRESS,
  TENET_CALCULATOR_ADDRESS_ETH,
} from "../../contracts/tenetCalculator";
import CalculatorAbi from "../../contracts/tenetCalculator/TenetCalculator.json";
import useStakingStore from "../Staking/store";
import { StakeDataType } from "../../types/interfaces";
import { useHandleError } from "../../hooks/useHandleError";
import { shallow } from "zustand/shallow";
import { beautifyTokenBalance } from "../../utils/bigNumber";
import { useToaster } from "../Toaster/ToasterContext";
import { useEthersContract } from "../../hooks/useEthersContract";
import useMulticall from "../../hooks/useMulticall";
import { useWeb3Connect } from "../../contexts/Web3ProviderContext";
import { useContract } from "../../hooks/useContract";
import { useStakingIteractor } from "../Staking/iteractor";
import StakingAbiErc20 from "../../contracts/tenetStaking/TenetStakingNativeErc20.json";
import { ethers } from "ethers";

export const useWithdrawIteractor = () => {
  const { account, isEthChainConnected } = useWeb3Connect();
  const { setAlert } = useToaster();
  const { stakingDecimals } = useAppStore((store) => ({ ...store }), shallow);
  const { newEpoch, stakerStakeCount, setIsUnstakedCanceled, stakedBalance } =
    useStakingStore((store) => ({ ...store }), shallow);
  const stakingContract = useEthersContract(
    isEthChainConnected ? TENET_STAKING_ADDRESS_ETH : TENET_STAKING_ADDRESS,
    isEthChainConnected ? StakingAbiErc20 : StakingAbi
  );
  const stakingContract2 = useContract(
    isEthChainConnected ? TENET_STAKING_ADDRESS_ETH : TENET_STAKING_ADDRESS,
    isEthChainConnected ? StakingAbiErc20 : StakingAbi
  );
  const calculatorContract = useEthersContract(
    isEthChainConnected
      ? TENET_CALCULATOR_ADDRESS_ETH
      : TENET_CALCULATOR_ADDRESS,
    CalculatorAbi
  );
  const _handleError = useHandleError();
  const multicall = useMulticall();
  const { getStakingInfo } = useStakingIteractor();
  const getAndUpdateWithdrawInfo = useCallback(async () => {
    const isAbleToGetWithdraws =
      stakingContract &&
      calculatorContract &&
      account &&
      newEpoch &&
      multicall &&
      stakerStakeCount > 0;
    if (isAbleToGetWithdraws) {
      try {
        useStakingStore.setState({ gettingWithdraws: true });
        const encodeResult = new Array(+stakerStakeCount)
          .fill(1)
          .map((item: any, index: any) => {
            return {
              address: isEthChainConnected
                ? TENET_STAKING_ADDRESS_ETH
                : TENET_STAKING_ADDRESS,
              callData: stakingContract.interface.encodeFunctionData(
                "stakers",
                [account, index]
              ),
            };
          });
        const callData = encodeResult.map((obj) => [obj.address, obj.callData]);

        const newStakers: StakeDataType[] = [];

        const [_, returnData] = await multicall.aggregate(callData);

        const result = returnData.reduce(
          (response: any[], aggregateItemResult: any, i: number) => {
            response[i] = stakingContract.interface.decodeFunctionResult(
              "stakers",
              aggregateItemResult
            );
            return response;
          },
          []
        );

        let rewardResults;
        let rewardPerSecondResults;

        if (newEpoch.isValid) {
          const rewardCallData = result.map((o: any, index: any) => {
            return {
              address: isEthChainConnected
                ? TENET_CALCULATOR_ADDRESS_ETH
                : TENET_CALCULATOR_ADDRESS,
              callData: calculatorContract.interface.encodeFunctionData(
                "rewardByStake(uint256,address,uint256)",
                [newEpoch.epochIndex, account, index]
              ),
              method: "rewardByStake(uint256,address,uint256)",
            };
          });
          const rewardPerSecondCallData = result.map((o: any, index: any) => {
            return {
              address: isEthChainConnected
                ? TENET_CALCULATOR_ADDRESS_ETH
                : TENET_CALCULATOR_ADDRESS,
              callData: calculatorContract.interface.encodeFunctionData(
                "rewardPerSecond(uint256,address,uint256)",
                [newEpoch.epochIndex, account, index]
              ),
              method: "rewardPerSecond(uint256,address,uint256)",
            };
          });
          const callRewardData: any[] = [
            ...rewardCallData,
            ...rewardPerSecondCallData,
          ].map((obj) => [obj.address, obj.callData]);

          const [k, returnData2] = await multicall.aggregate(callRewardData);

          const callRewardDataResult = returnData2.map(
            (call: any, index: any) => {
              return calculatorContract.interface.decodeFunctionResult(
                [...rewardCallData, ...rewardPerSecondCallData][index].method,
                call
              );
            }
          );
          rewardResults = callRewardDataResult.slice(0, rewardCallData.length);
          rewardPerSecondResults = callRewardDataResult.slice(
            rewardPerSecondCallData.length,
            rewardCallData.length + rewardPerSecondCallData.length
          );
        }
        const withdrawalsCallData = result.map((elem: any, index: number) => [
          isEthChainConnected
            ? TENET_STAKING_ADDRESS_ETH
            : TENET_STAKING_ADDRESS,
          stakingContract.interface.encodeFunctionData("withdrawals", [
            account,
            index,
          ]),
        ]);

        const [o, withdrawals] = await multicall.aggregate(withdrawalsCallData);
        const callRewardDataResult = withdrawals.map(
          (call: any, index: any) => {
            return stakingContract.interface.decodeFunctionResult(
              "withdrawals",
              call
            );
          }
        );
        for (let i = 0; i < result.length; ++i) {
          const withdrawStatus = callRewardDataResult[i];
          const unstaked = result[i][0];
          if (!withdrawStatus.withdrawn) {
            if (unstaked && Number(withdrawStatus.withdrawalTimestamp) === 0) {
              continue;
            }
            const newStaker = {
              ...result[i],
              id: i,
              amount: beautifyTokenBalance(
                String(result[i].amount),
                stakingDecimals
              ),
              withdrawTimestamp: withdrawStatus.withdrawalTimestamp,
            };
            if (newEpoch.isValid) {
              newStaker.reward = ethers.utils.parseEther(
                rewardResults[i].reward.toString()
              );
              newStaker.rewardPerSecond = ethers.utils.parseEther(
                rewardPerSecondResults[i].reward.toString()
              );
            }
            newStakers.push(newStaker);
          }
        }
        useStakingStore.setState({ stakers: newStakers });
      } catch (e) {
        _handleError(e, "Error during getting your stakes");
      } finally {
        useStakingStore.setState({ gettingWithdraws: false });
      }
    }
  }, [
    stakingContract,
    stakedBalance,
    calculatorContract,
    account,
    newEpoch,
    stakingDecimals,
    multicall,
    stakerStakeCount,
  ]);

  const unstake = useCallback(
    async (id: number) => {
      if (stakingContract2 && account) {
        try {
          useStakingStore.setState({ unstaking: { loading: true, id } });
          const tx = await stakingContract2.unstake(id, { from: account });
          await tx.wait()
          await getAndUpdateWithdrawInfo();
          setAlert({
            shown: true,
            message: "Successfully unstaked",
            severity: "success",
          });
        } catch (e) {
          _handleError(e, "Failed to unstake");
        } finally {
          useStakingStore.setState({ unstaking: { loading: false, id: -1 } });
        }
      }
    },
    [stakingContract2, account]
  );

  const cancelUnstake = useCallback(
    async (id: number) => {
      if (stakingContract2 && account) {
        try {
          useStakingStore.setState({ unstaking: { loading: true, id } });
          const tx = await stakingContract2.cancelUnstake(id, { from: account });
          await tx.wait()
          setIsUnstakedCanceled(true);
          await getAndUpdateWithdrawInfo();
          return true;
        } catch (e) {
          _handleError(e, "Failed to cancel unstake");
          return false;
        } finally {
          useStakingStore.setState({ unstaking: { loading: false, id: -1 } });
        }
      }
    },
    [stakingContract, account]
  );

  const withdraw = useCallback(
    async (id: number, force = true) => {
      if (stakingContract2 && account) {
        try {
          useStakingStore.setState({ unstaking: { loading: true, id } });
          const tx = await stakingContract2.withdraw(id, force, { from: account });
          await tx.wait()
          setAlert({
            shown: true,
            message: "Successfully withdrawn",
            severity: "warning",
          });
          await getAndUpdateWithdrawInfo();
          await getStakingInfo();
          return true;
        } catch (e) {
          _handleError(e, "Failed to withdraw");
          return false;
        } finally {
          useStakingStore.setState({ unstaking: { loading: false, id: -1 } });
        }
      }
    },
    [stakingContract2, account]
  );

  return { getAndUpdateWithdrawInfo, unstake, cancelUnstake, withdraw };
};
