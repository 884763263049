import css from "./styles.module.scss";
import Vector from "../../assets/getTenetIcon/Vector.png";
import Left from "../../assets/getTenetIcon/left.svg";
import { arrCrypto } from "../../constants/mockups";

export const GetTenetView = () => {
  return (
    <div className={css.getTenetWrapper}>
      <h2 className={css.getTenetTitle}>Get Tenet</h2>
      <ul className={css.getTenetBox}>
        {arrCrypto.map((a, i) => (
          <a href={a.link} key={i} target="_blank" rel="noreferrer">
            <li
              className={
                a.isWarningVisible
                  ? css.getTenetItemIsWarning
                  : css.getTenetItem
              }
            >
              <div className={css.getTenetTitleBox}>
                <div className={css.getTenetIcon}>
                  <img src={a.icon} />
                </div>
                <h3>{a.text}</h3>
              </div>
              <div className={css.getTenetIconFlex}>
                {a.isWarningVisible && (
                  <div className={css.getTenetIconIsWarning}>
                    <img alt={"token icon"} src={Vector} />
                  </div>
                )}
                <div className={css.getTenetIconRight}>
                  <img src={Left} />
                </div>
              </div>
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
};
