import React from 'react';

export const BlueSmile = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4834 25.8914C16.5282 26.4039 18.8481 26.4042 21.1825 25.7787C23.5169 25.1532 25.5258 23.993 27.0404 22.5267"
        stroke="#5B68DF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <ellipse
        cx="23.5775"
        cy="15.8836"
        rx="1.625"
        ry="2.4375"
        transform="rotate(-15 23.5775 15.8836)"
        fill="#5B68DF"
      />
      <ellipse
        cx="14.1605"
        cy="18.4076"
        rx="1.625"
        ry="2.4375"
        transform="rotate(-15 14.1605 18.4076)"
        fill="#5B68DF"
      />
      <path
        d="M5.20579 23.3301C3.40025 16.5918 2.49748 13.2226 4.02991 10.5683C5.56234 7.91409 8.93152 7.01133 15.6699 5.20579C22.4082 3.40025 25.7774 2.49748 28.4317 4.02991C31.0859 5.56234 31.9887 8.93152 33.7942 15.6699C35.5997 22.4082 36.5025 25.7774 34.9701 28.4317C33.4377 31.0859 30.0685 31.9887 23.3301 33.7942C16.5918 35.5997 13.2226 36.5025 10.5683 34.9701C7.91409 33.4377 7.01133 30.0685 5.20579 23.3301Z"
        stroke="#5B68DF"
        strokeWidth="1.5"
      />
      <path
        d="M21.125 25.9999L21.9017 27.5823C22.4889 28.7786 23.928 29.2815 25.1325 28.7113C26.3613 28.1296 26.8766 26.6549 26.2775 25.4345L25.5312 23.9141"
        stroke="#5B68DF"
        strokeWidth="1.5"
      />
    </svg>
  );
};
