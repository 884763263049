export const shortenAddress = (address: string, chars = 5): string => {
  return `${address.substring(0, chars)}...${address.substring(42 - 4)}`;
};

export const getDdMmYy = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}.${month}.${year}`;
};

export const getDdMonthNameTimeUtc = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const utcString = date.toLocaleString("en-US", {
    ...options,
    timeZone: "UTC",
  });
  return utcString + " UTC";
};

export const toNormalFixed = (num: string | number, afterDot = 2): string => {
  let stringifiedNum = typeof num === "number" ? num.toString() : num;
  const reg = new RegExp(`(\\.\\d{${afterDot}})\\d+`, "g");
  const value = stringifiedNum.replace(reg, "$1");
  return value;
};

export const calculateIncomeBonus = (income: number, value: number) => {
  return income - value / 100;
};
