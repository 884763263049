import React from 'react';

export const Logo = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="113"
      height="25"
      viewBox="0 0 113 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.344286 6.75283V0.899959H21.8622V6.75283H14.2879V25H7.91858V6.75283H0.344286ZM23.7396 25V0.899959H42.3311V6.75283H30.1089V10.3678H39.9211V15.5321H30.1089V19.1471H42.3311V25H23.7396ZM45.2414 25V0.899959H52.9878L61.4228 16.0486H61.7671L61.2507 12.95V0.899959H67.62V25H59.8735L51.4385 9.8514H51.0942L51.6107 12.95V25H45.2414ZM71.4165 25V0.899959H90.008V6.75283H77.7858V10.3678H87.598V15.5321H77.7858V19.1471H90.008V25H71.4165ZM91.0247 6.75283V0.899959H112.543V6.75283H104.968V25H98.599V6.75283H91.0247Z"
        fill="white"
      />
    </svg>
  );
};
