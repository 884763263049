import { useToaster } from "../modules/Toaster/ToasterContext";
import { useCallback } from "react";
import { isMainnet } from "../utils/network";

export const useHandleError = () => {
  const { setAlert } = useToaster();

  return useCallback((e: unknown, userText = "") => {
    !isMainnet && console.log(e);
    if (e instanceof Error) {
      const errorText = isMainnet && userText ? userText : e.message;
      //setAlert({ shown: true, message: errorText, severity: "error" });
    }
  }, []);
};
