import React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      onClick={props.onClick}
      className={props.className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.89477 7.00001L0.197266 12.6975L1.30227 13.8025L6.99977 8.10501L12.6973 13.8025L13.8023 12.6975L8.10477 7.00001L13.8023 1.30251L12.6973 0.19751L6.99977 5.89501L1.30227 0.19751L0.197266 1.30251L5.89477 7.00001Z"
        fill="#7E87A1"
      />
    </svg>
  );
};
