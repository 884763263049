import React, { createContext, useContext } from "react";
import useWeb3Provider from "../hooks/useWeb3Provider";
import { IProvider } from "../types/interfaces";

interface IContext {
  web3Provider: IProvider | undefined;
  account: string | undefined;
  isCorrectChainId: boolean | undefined;
  onConnect: (isOnRefresh?: boolean) => void;
  onDisconnect: () => void;
  networkId: number | undefined;
  currencySymbol: string;
  isEthChainConnected: boolean | undefined;
}

const Web3ProviderContext = createContext<IContext>({
  web3Provider: undefined,
  account: "",
  isCorrectChainId: false,
  onConnect: () => {},
  onDisconnect: () => {},
  networkId: undefined,
  currencySymbol: "TENET",
  isEthChainConnected: undefined,
});

export const useWeb3Connect = () => useContext(Web3ProviderContext);

const Web3ProviderContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    provider: web3Provider,
    account,
    isCorrectChainId,
    onConnect,
    onDisconnect,
    networkId,
    currencySymbol,
    isEthChainConnected,
  } = useWeb3Provider();

  return (
    <Web3ProviderContext.Provider
      value={{
        web3Provider,
        account,
        isCorrectChainId,
        onConnect,
        onDisconnect,
        networkId,
        currencySymbol,
        isEthChainConnected,
      }}
    >
      {children}
    </Web3ProviderContext.Provider>
  );
};

export { Web3ProviderContext, Web3ProviderContextProvider };
