import React from 'react';

export const WalletIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 15H3.75C3.35218 15 2.97064 14.842 2.68934 14.5607C2.40804 14.2794 2.25 13.8978 2.25 13.5V6.75C2.25 6.35218 2.40804 5.97064 2.68934 5.68934C2.97064 5.40804 3.35218 5.25 3.75 5.25H14.25C14.6478 5.25 15.0294 5.40804 15.3107 5.68934C15.592 5.97064 15.75 6.35218 15.75 6.75V13.5C15.75 13.8978 15.592 14.2794 15.3107 14.5607C15.0294 14.842 14.6478 15 14.25 15Z"
        stroke="#7E87A1"
      />
      <path
        d="M14.25 15H3.75C3.35218 15 2.97064 14.842 2.68934 14.5607C2.40804 14.2794 2.25 13.8978 2.25 13.5V6.75C2.25 6.35218 2.40804 5.97064 2.68934 5.68934C2.97064 5.40804 3.35218 5.25 3.75 5.25H14.25C14.6478 5.25 15.0294 5.40804 15.3107 5.68934C15.592 5.97064 15.75 6.35218 15.75 6.75V13.5C15.75 13.8978 15.592 14.2794 15.3107 14.5607C15.0294 14.842 14.6478 15 14.25 15Z"
        stroke="url(#paint0_linear_4476_9593)"
      />
      <path
        d="M12.375 10.5C12.2755 10.5 12.1802 10.4605 12.1098 10.3902C12.0395 10.3198 12 10.2245 12 10.125C12 10.0255 12.0395 9.93016 12.1098 9.85983C12.1802 9.78951 12.2755 9.75 12.375 9.75C12.4745 9.75 12.5698 9.78951 12.6402 9.85983C12.7105 9.93016 12.75 10.0255 12.75 10.125C12.75 10.2245 12.7105 10.3198 12.6402 10.3902C12.5698 10.4605 12.4745 10.5 12.375 10.5Z"
        fill="#7E87A1"
      />
      <path
        d="M12.375 10.5C12.2755 10.5 12.1802 10.4605 12.1098 10.3902C12.0395 10.3198 12 10.2245 12 10.125C12 10.0255 12.0395 9.93016 12.1098 9.85983C12.1802 9.78951 12.2755 9.75 12.375 9.75C12.4745 9.75 12.5698 9.78951 12.6402 9.85983C12.7105 9.93016 12.75 10.0255 12.75 10.125C12.75 10.2245 12.7105 10.3198 12.6402 10.3902C12.5698 10.4605 12.4745 10.5 12.375 10.5Z"
        fill="url(#paint1_linear_4476_9593)"
      />
      <path
        d="M12.375 10.5C12.2755 10.5 12.1802 10.4605 12.1098 10.3902C12.0395 10.3198 12 10.2245 12 10.125C12 10.0255 12.0395 9.93016 12.1098 9.85983C12.1802 9.78951 12.2755 9.75 12.375 9.75C12.4745 9.75 12.5698 9.78951 12.6402 9.85983C12.7105 9.93016 12.75 10.0255 12.75 10.125C12.75 10.2245 12.7105 10.3198 12.6402 10.3902C12.5698 10.4605 12.4745 10.5 12.375 10.5Z"
        stroke="#7E87A1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.375 10.5C12.2755 10.5 12.1802 10.4605 12.1098 10.3902C12.0395 10.3198 12 10.2245 12 10.125C12 10.0255 12.0395 9.93016 12.1098 9.85983C12.1802 9.78951 12.2755 9.75 12.375 9.75C12.4745 9.75 12.5698 9.78951 12.6402 9.85983C12.7105 9.93016 12.75 10.0255 12.75 10.125C12.75 10.2245 12.7105 10.3198 12.6402 10.3902C12.5698 10.4605 12.4745 10.5 12.375 10.5Z"
        stroke="url(#paint2_linear_4476_9593)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 5.24997V4.20222C13.4999 3.97235 13.447 3.74556 13.3454 3.53938C13.2438 3.3332 13.0961 3.15314 12.9138 3.0131C12.7315 2.87307 12.5195 2.7768 12.294 2.73174C12.0686 2.68668 11.8359 2.69403 11.6137 2.75322L3.36375 4.95297C3.04429 5.0381 2.7619 5.22639 2.56049 5.48856C2.35908 5.75074 2.24993 6.07211 2.25 6.40272V6.74997"
        stroke="url(#paint3_linear_4476_9593)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4476_9593"
          x1="2.25"
          y1="9.00001"
          x2="18.8748"
          y2="8.75657"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0378695" stopColor="#73E0A9" />
          <stop offset="1" stopColor="#3F5EFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4476_9593"
          x1="12"
          y1="10.0385"
          x2="12.9237"
          y2="10.0287"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0378695" stopColor="#73E0A9" />
          <stop offset="1" stopColor="#3F5EFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4476_9593"
          x1="12"
          y1="10.0385"
          x2="12.9237"
          y2="10.0287"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0378695" stopColor="#73E0A9" />
          <stop offset="1" stopColor="#3F5EFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4476_9593"
          x1="2.25"
          y1="4.25931"
          x2="16.095"
          y2="3.85232"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0378695" stopColor="#73E0A9" />
          <stop offset="1" stopColor="#3F5EFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
