import React, { useEffect } from "react";
import css from "./styles.module.scss";
import { StakingItem } from "./components/StakingItem";
import useStakingStore from "../Staking/store";
import { useWithdrawIteractor } from "./ineractor";
import { ceilNumber, toHRNumberFloat } from "../../utils/bigNumber";
import useAppStore from "../../stores/appStore";
import CircularProgress from "@mui/material/CircularProgress";
import { getRewardPerDay } from "../../utils/time";
import { SuccessStakeUnstake } from "../../UI-core/SuccessStakeUnstake";
import UiDrawer from "../../UI-core/Drawer/inex";
import { useWeb3Connect } from "../../contexts/Web3ProviderContext";
import { shallow } from "zustand/shallow";

interface PropsTypes {
  isOpen: boolean;
}

export const WithdrawalView = ({ isOpen }: PropsTypes) => {
  const {
    stakers,
    gettingWithdraws,
    isUnstakedCanceled,
    setIsUnstakedCanceled,
    penaltyDays,
  } = useStakingStore((store) => ({ ...store }), shallow);
  const { stakingDecimals } = useAppStore();
  const { getAndUpdateWithdrawInfo } = useWithdrawIteractor();
  const { account, isEthChainConnected } = useWeb3Connect();

  useEffect(() => {
    if (isOpen) {
      getAndUpdateWithdrawInfo();
    }
  }, [isOpen, account, isEthChainConnected]);
  return (
    <section className={css.withdrawalWrapper}>
      <h2 className={css.withdrawTitle}>Withdraw</h2>
      <p className={css.withdrawText}>
        After you click on Unstake button, the cooldown period of {penaltyDays}{" "}
        days begins. Entries won't be applied during cooldown period.
      </p>
      <p className={css.warningWithdrawText}>
        Select the stake for the withdrawal request:
      </p>
      {gettingWithdraws && (
        <div className={css.loaderWrapper}>
          <CircularProgress
            size={50}
            classes={{
              colorPrimary: css.loaderColor,
            }}
          />
        </div>
      )}
      {!gettingWithdraws &&
        stakers?.map((stakeData) => {
          const perDayAmount = stakeData.rewardPerSecond
            ? ceilNumber(
                +toHRNumberFloat(
                  getRewardPerDay(stakeData.rewardPerSecond),
                  stakingDecimals
                )
              ).toLocaleString("en-us")
            : 0;
          return (
            <StakingItem
              isUnstaked={stakeData.unstaked}
              amount={stakeData.amount}
              penaltyDays={stakeData.penaltyDays}
              stakingDate={new Date(+(stakeData.stakedTimestamp ?? 0) * 1000)}
              perDayAmount={perDayAmount.toString()}
              untilPenaltyDate={new Date(+stakeData.withdrawTimestamp * 1000)}
              id={stakeData.id}
              key={stakeData.id}
              reward={stakeData.reward}
              penaltyBP={stakeData.penaltyBP}
            />
          );
        })}
      <UiDrawer
        onClose={() => setIsUnstakedCanceled(false)}
        open={isUnstakedCanceled}
      >
        <SuccessStakeUnstake
          title={"You will receive an Unstake Bonus"}
          subtitle={
            "After canceling unstake you will get all Drop Tickets for the past cooldown period. Also you will remain in the same yield pool that you were in before the unstake."
          }
          onClose={() => setIsUnstakedCanceled(false)}
        />
      </UiDrawer>
    </section>
  );
};
