import { AlertColor } from "@mui/material/Alert";
import Web3 from "web3";
import { IProvider } from "../types/interfaces";

export type NetworkType = "tenet" | "ethereum";
export const isMainnet = process.env.IS_MAINNET === "true";

export const NETWORK: Record<NetworkType, NetworkType> = {
  tenet: "tenet",
  ethereum: "ethereum",
};

export const networkNames = {
  [NETWORK.tenet]: isMainnet ? "TENET Mainnet" : "TENET Testnet",
  [NETWORK.ethereum]: isMainnet ? "Ethereum" : "Goerli",
};

const inverse = (obj: Record<any, any>) =>
  Object.fromEntries(Object.entries(obj).map((a) => a.reverse()));

export const idToNetwork: Record<number, NetworkType> = isMainnet
  ? {
      1559: NETWORK.tenet,
      1: NETWORK.ethereum,
    }
  : {
      155: NETWORK.tenet,
      5: NETWORK.ethereum,
    };

export const networkToId = inverse(idToNetwork);

export const shortenNetworkNames: Record<number, string> = isMainnet
  ? {
      1559: "Tenet Mainnet",
      1: "Ethereum",
    }
  : {
      155: "Tenet Testnet",
      5: "Goerli",
    };

export const networkInfo = {
  [NETWORK.tenet]: {
    chainName: networkNames[NETWORK.tenet],
    chainId: Web3.utils.toHex(networkToId[NETWORK.tenet]),
    rpcUrls: [
      isMainnet ? "https://rpc.tenet.org/" : "https://rpc.testnet.tenet.org/",
    ],
    blockExplorerUrls: [
      isMainnet ? "https://tenetscan.io/" : "https://testnet.tenetscan.io/",
    ],
    nativeCurrency: {
      name: "TENET",
      symbol: "TENET",
      decimals: 18,
    },
  },
  [NETWORK.ethereum]: {
    chainName: networkNames[NETWORK.ethereum],
    chainId: Web3.utils.toHex(networkToId[NETWORK.ethereum]),
    rpcUrls: [
      isMainnet
        ? "https://eth.llamarpc.com"
        : "https://ethereum-goerli.publicnode.com",
    ],
    blockExplorerUrls: [
      isMainnet ? "https://etherscan.io" : "https://goerli.etherscan.io",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
};

export const checkSupportedChainId = (chainId: number | string) => {
  const chainsWhiteList = Object.keys(idToNetwork).map((item) =>
    item.toString()
  );

  return chainsWhiteList.includes(chainId.toString());
};

export const changeNetworkAtMetamask = async (
  web3Provider: IProvider | undefined,
  networkName: NetworkType,
  setAlert?: React.Dispatch<
    React.SetStateAction<{
      severity?: AlertColor | undefined;
      shown: boolean;
      message?: string | undefined;
    }>
  >
) => {
  try {
    if (!web3Provider) {
      console.log("Provider wasn't found");
      return;
    }
    if (web3Provider?.provider?.request) {
      await web3Provider.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${networkToId[networkName].toString(16)}` }],
      });
      localStorage.setItem("network", networkName);
      setAlert &&
        setTimeout(
          () =>
            setAlert({
              shown: true,
              message: `Network was changed to ${networkName}`,
              severity: "info",
            }),
          2000
        );
    }
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (
      switchError.code === 4902 ||
      switchError.code === 5000 ||
      switchError.code === -32603
    ) {
      try {
        if (web3Provider?.provider?.request) {
          await web3Provider?.provider?.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: networkInfo[networkName].chainId,
                chainName: networkInfo[networkName].chainName,
                nativeCurrency: {
                  symbol: networkInfo[networkName].nativeCurrency.symbol,
                  decimals: networkInfo[networkName].nativeCurrency.decimals,
                },
                rpcUrls: networkInfo[networkName].rpcUrls,
              },
            ],
          });
          localStorage.setItem("network", networkName);
          setAlert &&
            setAlert({
              shown: true,
              message: `Successfully added ${networkName} network`,
              severity: "info",
            });
        }
      } catch (addError) {
        console.error(addError);
      }
    }
  }
};

export const getShortAddress = (address: string | null) =>
  address
    ? `${address.slice(0, 6)}...${address.slice(address.length - 3)}`
    : "";
