import useAppStore from "../stores/appStore";
import { toNormalFixed } from "../utils";
import { ethers } from "ethers";
import { useWeb3Connect } from "../contexts/Web3ProviderContext";
import { useCallback } from "react";
import { useContract } from "./useContract";
import { ERC20_TOKEN_ADDRESS } from "../contracts/erc20";
import ERC20_ABI from "../contracts/erc20/Erc20ABI.json";

export const useGetUserBalance = () => {
  const { account, isEthChainConnected, web3Provider } = useWeb3Connect();
  const tokenContract = useContract(ERC20_TOKEN_ADDRESS, ERC20_ABI, false);
  return useCallback(async () => {
    if (account && web3Provider && isEthChainConnected !== undefined) {
      if (!isEthChainConnected) {
        const balanceBg = await web3Provider.getBalance(account);
        useAppStore.setState({
          userBalance: toNormalFixed(
            ethers.utils.formatUnits(balanceBg, 18),
            4
          ),
        });
      } else {
        if (tokenContract) {
          const balanceBg = await tokenContract.balanceOf(account);
          const decimals = await tokenContract.decimals();
          useAppStore.setState({
            userBalance: toNormalFixed(
              ethers.utils.formatUnits(balanceBg, +decimals),
              4
            ),
          });
        }
      }
    }
  }, [account, isEthChainConnected, tokenContract, web3Provider]);
};
