import { useContract } from "../../hooks/useContract";
import {
  TENET_STAKING_ADDRESS,
  TENET_STAKING_ADDRESS_ETH,
} from "../../contracts/tenetStaking";
import StakingAbi from "../../contracts/tenetStaking/TenetStakingNative.json";
import StakingAbiErc20 from "../../contracts/tenetStaking/TenetStakingNativeErc20.json";
import CalculatorAbi from "../../contracts/tenetCalculator/TenetCalculator.json";
import { useCallback, useEffect } from "react";
import { ethers } from "ethers";
import { CurrentEpochDataType } from "../../types/interfaces";
import useAppStore from "../../stores/appStore";
import {
  TENET_CALCULATOR_ADDRESS,
  TENET_CALCULATOR_ADDRESS_ETH,
} from "../../contracts/tenetCalculator";
import useStakingStore from "./store";
import { useHandleError } from "../../hooks/useHandleError";
import { ceilNumber, fromHRToBN, toHRNumberFloat } from "../../utils/bigNumber";
import { useWeb3Connect } from "../../contexts/Web3ProviderContext";
import { getRewardPerDay } from "../../utils/time";
import { useGetUserBalance } from "../../hooks/useGetUserBalance";
import { ERC20_TOKEN_ADDRESS } from "../../contracts/erc20";
import ERC20_ABI from "../../contracts/erc20/Erc20ABI.json";
import { shallow } from "zustand/shallow";

export const useStakingIteractor = () => {
  const { account, isEthChainConnected } = useWeb3Connect();
  const { stakingDecimals } = useAppStore();
  const { newEpoch, setIncome } = useStakingStore(
    (store) => ({ ...store }),
    shallow
  );
  const stakingContract = useContract(
    isEthChainConnected ? TENET_STAKING_ADDRESS_ETH : TENET_STAKING_ADDRESS,
    isEthChainConnected ? StakingAbiErc20 : StakingAbi,
    true
  );
  const calculatorContract = useContract(
    isEthChainConnected
      ? TENET_CALCULATOR_ADDRESS_ETH
      : TENET_CALCULATOR_ADDRESS,
    CalculatorAbi,
    false
  );
  const tokenContract = useContract(ERC20_TOKEN_ADDRESS, ERC20_ABI);
  const _handleError = useHandleError();
  const getUserBalance = useGetUserBalance();

  const _approveMaxAmount = useCallback(async () => {
    if (isEthChainConnected && tokenContract && account) {
      const tx = await tokenContract.approve(
        TENET_STAKING_ADDRESS_ETH,
        ethers.constants.MaxUint256,
        {
          from: account,
        }
      );
      return await tx.wait();
    }
    return;
  }, [tokenContract, isEthChainConnected, account]);

  const getStakingInfo = useCallback(async () => {
    try {
      useStakingStore.setState({ isGettingInfo: true });

      if (calculatorContract && stakingContract && account) {
        await getUserBalance();
        const stakedBalanceBg = await stakingContract.balanceOf(account);
        const stakedBalance = toHRNumberFloat(
          stakedBalanceBg,
          +stakingDecimals
        );
        let newEpoch =
          (await calculatorContract.currentEpoch()) as CurrentEpochDataType;
        if (!newEpoch.isValid) {
          await calculatorContract
            .epochs(1)
            .then((res: any) => {
              newEpoch = {
                epochIndex: "1",
                epoch: res,
                isValid: false,
              } as CurrentEpochDataType;
            })
            .catch((e: any) => _handleError(e, "Get your staking info error"));
        }
        const stakerStakeCount = Number(
          await stakingContract.stakerStakeCount(account)
        );
        const maxBPS = await stakingContract.MAX_BPS();
        const penaltyDays = await stakingContract.penaltyDays();
        useStakingStore.setState({
          untilRaffleDate: new Date(
            +(newEpoch.epoch.epochEndTimestamp ?? 0) * 1000
          ),
          raffles: String(+newEpoch.epochIndex + 1),
          newEpoch,
          stakedBalance: stakedBalance.toString(),
          stakerStakeCount,
          penaltyDays: +penaltyDays,
          maxBPS,
        });
        if (newEpoch.isValid && account && stakerStakeCount > 0) {
          const newReward = await calculatorContract.rewardByStaker(
            newEpoch.epochIndex,
            account
          );

          const perDayBg = await calculatorContract[
            "rewardPerSecond(uint256,address)"
          ](newEpoch.epochIndex, account);
          const perDay = toHRNumberFloat(
            getRewardPerDay(perDayBg),
            +stakingDecimals
          );
          useStakingStore.setState({
            totalEntries: +toHRNumberFloat(newReward, stakingDecimals) || 0,
            entriesPerDay: +perDay,
          });
        }
      }
    } catch (e) {
      console.log(e);
      _handleError(e);
    } finally {
      useStakingStore.setState({ isGettingInfo: false });
    }
  }, [calculatorContract, stakingContract, _approveMaxAmount]);

  const stakeTokens = useCallback(
    async (amountToStake: number) => {
      try {
        useStakingStore.setState({ isStaking: true });
        const amountBN = ethers.utils.parseEther(amountToStake.toString());
        if (stakingContract && tokenContract) {
          if (isEthChainConnected) {
            const allowance = await tokenContract.allowance(
              account,
              TENET_STAKING_ADDRESS_ETH
            );
            if (ethers.BigNumber.from(allowance).lt(amountBN)) {
              await _approveMaxAmount();
            }
            // alternative approach
            //const amountBn = fromHRToBN(amountToStake, +stakingDecimals).toString();
            const tx = await stakingContract.stake(amountBN, {
              from: account,
            });
            await tx.wait();
          } else {
            const tx = await stakingContract.stake({
              from: account,
              value: amountBN,
            });
            await tx.wait();
          }

          await getStakingInfo();
          return true;
        }
      } catch (e) {
        console.log(e);
        if (e instanceof Error) {
          if (
            e.message !== "stakingContract.stake(...).send is not a function"
          ) {
            _handleError(e);
          }
        }
      } finally {
        useStakingStore.setState({ isStaking: false });
      }
    },
    [calculatorContract, account, isEthChainConnected, tokenContract]
  );
  const calcShares = useCallback(
    async (value: string) => {
      if (
        stakingContract &&
        calculatorContract &&
        stakingDecimals &&
        newEpoch
      ) {
        const shares = await stakingContract["calculateShares(uint256)"](
          fromHRToBN(+value, stakingDecimals)
        );
        const newIncome = await calculatorContract[
          "rewardPerSecond(uint256,uint256)"
        ](newEpoch?.epochIndex ?? 0, shares);

        try {
          const res = toHRNumberFloat(
            getRewardPerDay(newIncome),
            stakingDecimals
          );
          const ceilRes = ceilNumber(+res);
          setIncome(ceilRes);
        } catch (e) {
          setIncome(0);
        }
      }
    },
    [stakingContract, calculatorContract, stakingDecimals, newEpoch]
  );

  useEffect(() => {
    getStakingInfo();
  }, [getStakingInfo]);

  return { stakeTokens, getStakingInfo, calcShares };
};
