import React from 'react';

export const DiscordIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2701 1.33005C16.9401 0.710046 15.5001 0.260046 14.0001 4.59982e-05C13.987 -0.000374605 13.9739 0.00209348 13.9618 0.00727676C13.9497 0.01246 13.9389 0.0202326 13.9301 0.0300462C13.7501 0.360046 13.5401 0.790046 13.4001 1.12005C11.8091 0.880046 10.1911 0.880046 8.60012 1.12005C8.46012 0.780046 8.25012 0.360046 8.06012 0.0300462C8.05012 0.0100462 8.02012 4.59982e-05 7.99012 4.59982e-05C6.49012 0.260046 5.06012 0.710046 3.72012 1.33005C3.71012 1.33005 3.70012 1.34005 3.69012 1.35005C0.970117 5.42005 0.220117 9.38005 0.590117 13.3C0.590117 13.3201 0.600117 13.3401 0.620117 13.3501C2.42012 14.6701 4.15012 15.4701 5.86012 16.0001C5.89012 16.0101 5.92012 16.0001 5.93012 15.9801C6.33012 15.4301 6.69012 14.8501 7.00012 14.2401C7.02012 14.2001 7.00012 14.1601 6.96012 14.1501C6.39012 13.9301 5.85012 13.6701 5.32012 13.3701C5.28012 13.3501 5.28012 13.2901 5.31012 13.2601C5.42012 13.1801 5.53012 13.0901 5.64012 13.0101C5.66012 12.9901 5.69012 12.9901 5.71012 13.0001C9.15012 14.5701 12.8601 14.5701 16.2601 13.0001C16.2801 12.9901 16.3101 12.9901 16.3301 13.0101C16.4401 13.1001 16.5501 13.1801 16.6601 13.2701C16.7001 13.3001 16.7001 13.36 16.6501 13.38C16.1301 13.6901 15.5801 13.9401 15.0101 14.1601C14.9701 14.1701 14.9601 14.2201 14.9701 14.2501C15.2901 14.8601 15.6501 15.4401 16.0401 15.9901C16.0701 16.0001 16.1001 16.0101 16.1301 16.0001C17.8501 15.4701 19.5801 14.6701 21.3801 13.3501C21.4001 13.3401 21.4101 13.3201 21.4101 13.3C21.8501 8.77005 20.6801 4.84005 18.3101 1.35005C18.3001 1.34005 18.2901 1.33005 18.2701 1.33005ZM7.52012 10.91C6.49012 10.91 5.63012 9.96005 5.63012 8.79005C5.63012 7.62005 6.47012 6.67005 7.52012 6.67005C8.58012 6.67005 9.42012 7.63005 9.41012 8.79005C9.41012 9.96005 8.57012 10.91 7.52012 10.91ZM14.4901 10.91C13.4601 10.91 12.6001 9.96005 12.6001 8.79005C12.6001 7.62005 13.4401 6.67005 14.4901 6.67005C15.5501 6.67005 16.3901 7.63005 16.3801 8.79005C16.3801 9.96005 15.5501 10.91 14.4901 10.91Z"
        fill="#E2E8F1"
      />
    </svg>
  );
};
